import React, { FC, useContext } from 'react'
import css from './Body.module.scss'
import cx from 'classnames'
import { LayoutContext } from '../../../contexts/layoutProvider'
import { HeaderColors } from '../../types'
import InnerNav from '../Header/InnerNav/InnerNav'
interface IBody {
  children?: React.ReactNode
}

const Body: FC<IBody> = ({ children }: IBody) => {
  const { headerInitColor, showInnerNav, innerNav } = useContext(LayoutContext)

  return (
    <main
      className={cx(
        'body',
        css.wrapper,
        headerInitColor === HeaderColors.fixedTransparent && css.noPadd,
      )}
    >
      {innerNav && showInnerNav && <InnerNav items={innerNav} />}
      {children}
    </main>
  )
}

export default Body
