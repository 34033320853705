import React, { FC, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
  BodyScrollOptions,
} from 'body-scroll-lock'
import Link from 'next/link'
import cx from 'classnames'

import ArrowIcon from '../../public/static/icons/arrow-left.svg'
import CartIcon from '../../public/static/icons/cart.svg'
import css from './AsideModal.module.scss'
import { useRouter } from 'next/router'

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
}

interface AsideModal {
  open: boolean
  top?: JSX.Element
  className?: string
  onClose: () => void
}

const AsideModal: FC<AsideModal> = ({
  open,
  top,
  children,
  className,
  onClose,
}) => {
  const ref = useRef<Modal>()
  const router = useRouter()

  useEffect(() => {
    clearAllBodyScrollLocks(ref, options)
    onClose()
  }, [router.asPath])

  useEffect(() => {
    if (!window && !document) {
      return
    }

    const storedScrollY = window.scrollY

    if (open) {
      disableBodyScroll(ref.current, options)
      document.body.style.setProperty('top', `${window.scrollY * -1}px`)
    } else {
      setTimeout(() => {
        clearAllBodyScrollLocks(ref.current, options)
        document.body.style.setProperty('top', '')
        document.body.scrollTo(0, storedScrollY)
      }, 200)
    }
  }, [open])

  return (
    <Modal
      ref={ref}
      isOpen={open}
      className={css.content}
      overlayClassName={css.overlay}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      closeTimeoutMS={200}
      shouldFocusAfterRender={false}
      shouldReturnFocusAfterClose={false}
    >
      <PerfectScrollbar options={{ swipeEasing: true }}>
        <div className={cx(css.wrapper, className)}>
          {top || (
            <div className={css.buttons}>
              <button type="button" onClick={onClose}>
                <ArrowIcon />
              </button>

              <Link href="/" passHref>
                <a>
                  <CartIcon />
                </a>
              </Link>
            </div>
          )}
          <div className={css.children}>{children}</div>
        </div>
      </PerfectScrollbar>
    </Modal>
  )
}

export default AsideModal
