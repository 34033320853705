/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BasketCouponStatus {
  APPLYED = "APPLYED",
  BAD = "BAD",
  ENTERED = "ENTERED",
}

export enum BasketItemKind {
  SET = "SET",
  SINGLE = "SINGLE",
}

export enum ProductDiscuntKind {
  FIXED = "FIXED",
  PERCENT = "PERCENT",
  STATIC = "STATIC",
}

export enum ProductType {
  SET = "SET",
  SIMPLE = "SIMPLE",
  SKU = "SKU",
  WITH_SKU = "WITH_SKU",
}

export enum PropertyKind {
  ELEMENT = "ELEMENT",
  HLBLOCK = "HLBLOCK",
  SECTION = "SECTION",
  SETTINGS_PLUS = "SETTINGS_PLUS",
}

export enum PropertyType {
  ENUM = "ENUM",
  FILE = "FILE",
  LIST = "LIST",
  RELATION = "RELATION",
  STRING = "STRING",
  USER = "USER",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

export enum Socnet {
  fb = "fb",
  vk = "vk",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export interface BoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface BoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface CountMetricaListRelationFilter {
  every?: CountMetricaWhereInput | null;
  some?: CountMetricaWhereInput | null;
  none?: CountMetricaWhereInput | null;
}

export interface CountMetricaWhereInput {
  AND?: CountMetricaWhereInput[] | null;
  OR?: CountMetricaWhereInput[] | null;
  NOT?: CountMetricaWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  day?: DateTimeFilter | null;
  count?: IntFilter | null;
  element_id?: StringNullableFilter | null;
  element?: ElementWhereInput | null;
}

export interface CurrencyWhereInput {
  AND?: CurrencyWhereInput[] | null;
  OR?: CurrencyWhereInput[] | null;
  NOT?: CurrencyWhereInput[] | null;
  id?: StringFilter | null;
  code?: StringFilter | null;
  numcode?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  base?: BoolFilter | null;
  sort?: IntFilter | null;
  PriceValue?: PriceValueListRelationFilter | null;
}

export interface DateTimeFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeFilter | null;
}

export interface DateTimeNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
}

export interface DecimalFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalFilter | null;
}

export interface DecimalNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalNullableFilter | null;
}

export interface ElementListRelationFilter {
  every?: ElementWhereInput | null;
  some?: ElementWhereInput | null;
  none?: ElementWhereInput | null;
}

export interface ElementOrderByInput {
  id?: SortOrder | null;
  bx_id?: SortOrder | null;
  xml_id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  active?: SortOrder | null;
  activeFrom?: SortOrder | null;
  activeTo?: SortOrder | null;
  code?: SortOrder | null;
  detailText?: SortOrder | null;
  name?: SortOrder | null;
  previewText?: SortOrder | null;
  sort?: SortOrder | null;
  showCounter?: SortOrder | null;
  priceBase?: SortOrder | null;
  priceBaseWithDiscount?: SortOrder | null;
  priceBaseDiscountValue?: SortOrder | null;
  priceOpt?: SortOrder | null;
  priceOptWithDiscount?: SortOrder | null;
  priceOptDiscountValue?: SortOrder | null;
  productType?: SortOrder | null;
  quantity?: SortOrder | null;
  weight?: SortOrder | null;
  width?: SortOrder | null;
  length?: SortOrder | null;
  height?: SortOrder | null;
  canBuyZero?: SortOrder | null;
  available?: SortOrder | null;
  parentProduct_id?: SortOrder | null;
  iblock_id?: SortOrder | null;
  previewPicture_id?: SortOrder | null;
  detailPicture_id?: SortOrder | null;
  seo_id?: SortOrder | null;
  searchableContent?: SortOrder | null;
  tags?: SortOrder | null;
  createdBy_id?: SortOrder | null;
  modifiedBy_id?: SortOrder | null;
  parentSection_id?: SortOrder | null;
}

export interface ElementWhereInput {
  AND?: ElementWhereInput[] | null;
  OR?: ElementWhereInput[] | null;
  NOT?: ElementWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntNullableFilter | null;
  xml_id?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  active?: BoolFilter | null;
  activeFrom?: DateTimeNullableFilter | null;
  activeTo?: DateTimeNullableFilter | null;
  code?: StringNullableFilter | null;
  detailText?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  previewText?: StringNullableFilter | null;
  sort?: IntFilter | null;
  showCounter?: IntNullableFilter | null;
  countMetrica?: CountMetricaListRelationFilter | null;
  priceBase?: DecimalNullableFilter | null;
  priceBaseWithDiscount?: DecimalNullableFilter | null;
  priceBaseDiscountValue?: DecimalNullableFilter | null;
  priceOpt?: DecimalNullableFilter | null;
  priceOptWithDiscount?: DecimalNullableFilter | null;
  priceOptDiscountValue?: DecimalNullableFilter | null;
  priceValues?: PriceValueListRelationFilter | null;
  productType?: EnumProductTypeNullableFilter | null;
  quantity?: IntNullableFilter | null;
  weight?: IntNullableFilter | null;
  width?: IntNullableFilter | null;
  length?: IntNullableFilter | null;
  height?: IntNullableFilter | null;
  canBuyZero?: BoolNullableFilter | null;
  available?: BoolNullableFilter | null;
  sku?: ElementListRelationFilter | null;
  parentProduct_id?: StringNullableFilter | null;
  parentProduct?: ElementWhereInput | null;
  iblock?: IblockWhereInput | null;
  iblock_id?: StringNullableFilter | null;
  previewPicture_id?: StringNullableFilter | null;
  previewPicture?: FileWhereInput | null;
  detailPicture_id?: StringNullableFilter | null;
  detailPicture?: FileWhereInput | null;
  seo_id?: StringNullableFilter | null;
  seo?: SeoWhereInput | null;
  searchableContent?: StringNullableFilter | null;
  tags?: StringNullableFilter | null;
  createdBy_id?: StringNullableFilter | null;
  createdBy?: UserWhereInput | null;
  modifiedBy_id?: StringNullableFilter | null;
  modifiedBy?: UserWhereInput | null;
  parentSection_id?: StringNullableFilter | null;
  parentSection?: SectionWhereInput | null;
  sections?: SectionListRelationFilter | null;
  dirs?: SectionListRelationFilter | null;
  ChildElementsAllToSections?: SectionListRelationFilter | null;
  propertyValues?: PropertyValueListRelationFilter | null;
  propertyValuesEnum?: PropertyEnumListRelationFilter | null;
  propertyValuesRelation?: PropertyRelationListRelationFilter | null;
  PropertyRelationsTargetElement?: PropertyRelationListRelationFilter | null;
  PropertyRelationsTargetElements?: PropertyRelationListRelationFilter | null;
  ElementsToSku?: ElementListRelationFilter | null;
  ElementParentProduct?: ElementListRelationFilter | null;
  SettingPlusTargetElement?: SettingPlusListRelationFilter | null;
  SettingPlusTargetElements?: SettingPlusListRelationFilter | null;
  InUsersWishlist?: UserListRelationFilter | null;
}

export interface EnumProductTypeNullableFilter {
  equals?: ProductType | null;
  in?: ProductType[] | null;
  notIn?: ProductType[] | null;
  not?: NestedEnumProductTypeNullableFilter | null;
}

export interface EnumPropertyKindFilter {
  equals?: PropertyKind | null;
  in?: PropertyKind[] | null;
  notIn?: PropertyKind[] | null;
  not?: NestedEnumPropertyKindFilter | null;
}

export interface EnumPropertyTypeFilter {
  equals?: PropertyType | null;
  in?: PropertyType[] | null;
  notIn?: PropertyType[] | null;
  not?: NestedEnumPropertyTypeFilter | null;
}

export interface FileListRelationFilter {
  every?: FileWhereInput | null;
  some?: FileWhereInput | null;
  none?: FileWhereInput | null;
}

export interface FileWhereInput {
  AND?: FileWhereInput[] | null;
  OR?: FileWhereInput[] | null;
  NOT?: FileWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  sort?: IntNullableFilter | null;
  contentType?: StringFilter | null;
  name?: StringFilter | null;
  description?: StringNullableFilter | null;
  secret?: StringFilter | null;
  hash?: StringNullableFilter | null;
  uploaded?: BoolNullableFilter | null;
  size?: FloatFilter | null;
  width?: IntNullableFilter | null;
  height?: IntNullableFilter | null;
  duration?: FloatNullableFilter | null;
  source?: StringNullableFilter | null;
  url?: StringFilter | null;
  PropertyRelationTargetFile?: PropertyRelationListRelationFilter | null;
  PropertyRelationTargetFiles?: PropertyRelationListRelationFilter | null;
  ElementDetailPicture?: ElementWhereInput | null;
  ElementPreviewPicture?: ElementWhereInput | null;
  HlElementDetailPicture?: HlElementWhereInput | null;
  HlElementPreviewPicture?: HlElementWhereInput | null;
  SectionDetailPicture?: SectionWhereInput | null;
  SectionPreviewPicture?: SectionWhereInput | null;
  User?: UserListRelationFilter | null;
  Iblock?: IblockListRelationFilter | null;
  videoQuality?: VideoQualityListRelationFilter | null;
  imageQuality?: ImageQualityListRelationFilter | null;
  SettingPlusTargetFile?: SettingPlusListRelationFilter | null;
  SettingPlusTargetFiles?: SettingPlusListRelationFilter | null;
  HlElement?: HlElementListRelationFilter | null;
}

export interface FloatFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatFilter | null;
}

export interface FloatNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatNullableFilter | null;
}

export interface GroupListRelationFilter {
  every?: GroupWhereInput | null;
  some?: GroupWhereInput | null;
  none?: GroupWhereInput | null;
}

export interface GroupWhereInput {
  AND?: GroupWhereInput[] | null;
  OR?: GroupWhereInput[] | null;
  NOT?: GroupWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringNullableFilter | null;
  name?: StringFilter | null;
  active?: BoolFilter | null;
  sort?: IntFilter | null;
  description?: StringNullableFilter | null;
  users?: UserListRelationFilter | null;
}

export interface HlBlockWhereInput {
  AND?: HlBlockWhereInput[] | null;
  OR?: HlBlockWhereInput[] | null;
  NOT?: HlBlockWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  code?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  hlElements?: HlElementListRelationFilter | null;
}

export interface HlElementListRelationFilter {
  every?: HlElementWhereInput | null;
  some?: HlElementWhereInput | null;
  none?: HlElementWhereInput | null;
}

export interface HlElementWhereInput {
  AND?: HlElementWhereInput[] | null;
  OR?: HlElementWhereInput[] | null;
  NOT?: HlElementWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringNullableFilter | null;
  xml_id?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  color?: StringNullableFilter | null;
  description?: StringNullableFilter | null;
  detailText?: StringNullableFilter | null;
  previewText?: StringNullableFilter | null;
  sort?: IntNullableFilter | null;
  active?: BoolNullableFilter | null;
  default?: BoolNullableFilter | null;
  file_id?: StringNullableFilter | null;
  file?: FileWhereInput | null;
  previewPicture_id?: StringNullableFilter | null;
  previewPicture?: FileWhereInput | null;
  detailPicture_id?: StringNullableFilter | null;
  detailPicture?: FileWhereInput | null;
  propertyValues?: PropertyValueListRelationFilter | null;
  propertyValuesEnum?: PropertyEnumListRelationFilter | null;
  propertyValuesRelation?: PropertyRelationListRelationFilter | null;
  PropertyRelationsTargetHlElement?: PropertyRelationListRelationFilter | null;
  PropertyRelationsTargetHlElements?: PropertyRelationListRelationFilter | null;
  hlBlock?: HlBlockWhereInput | null;
  hlBlock_id?: StringNullableFilter | null;
}

export interface IblockListRelationFilter {
  every?: IblockWhereInput | null;
  some?: IblockWhereInput | null;
  none?: IblockWhereInput | null;
}

export interface IblockWhereInput {
  AND?: IblockWhereInput[] | null;
  OR?: IblockWhereInput[] | null;
  NOT?: IblockWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringNullableFilter | null;
  name?: StringFilter | null;
  active?: BoolFilter | null;
  sort?: IntFilter | null;
  type?: StringFilter | null;
  description?: StringNullableFilter | null;
  picture_id?: StringNullableFilter | null;
  picture?: FileWhereInput | null;
  elements?: ElementListRelationFilter | null;
  sections?: SectionListRelationFilter | null;
  isOffersIblock?: BoolNullableFilter | null;
  offersIblock_id?: StringNullableFilter | null;
  offersIblock?: IblockWhereInput | null;
  productsIblock_id?: StringNullableFilter | null;
  productsIblock?: IblockWhereInput | null;
  skuProperty_id?: StringNullableFilter | null;
  skuProperty?: PropertyWhereInput | null;
  IblockOffersIblock?: IblockListRelationFilter | null;
  IblockProductsIblock?: IblockListRelationFilter | null;
}

export interface ImageQualityListRelationFilter {
  every?: ImageQualityWhereInput | null;
  some?: ImageQualityWhereInput | null;
  none?: ImageQualityWhereInput | null;
}

export interface ImageQualityWhereInput {
  AND?: ImageQualityWhereInput[] | null;
  OR?: ImageQualityWhereInput[] | null;
  NOT?: ImageQualityWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  quality?: IntFilter | null;
  url?: StringFilter | null;
  size?: FloatFilter | null;
  width?: IntFilter | null;
  height?: IntFilter | null;
  file_id?: StringNullableFilter | null;
  file?: FileWhereInput | null;
}

export interface IntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface IntNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntNullableFilter | null;
}

export interface NestedBoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface NestedBoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface NestedDateTimeFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeFilter | null;
}

export interface NestedDateTimeNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
}

export interface NestedDecimalFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalFilter | null;
}

export interface NestedDecimalNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalNullableFilter | null;
}

export interface NestedEnumProductTypeNullableFilter {
  equals?: ProductType | null;
  in?: ProductType[] | null;
  notIn?: ProductType[] | null;
  not?: NestedEnumProductTypeNullableFilter | null;
}

export interface NestedEnumPropertyKindFilter {
  equals?: PropertyKind | null;
  in?: PropertyKind[] | null;
  notIn?: PropertyKind[] | null;
  not?: NestedEnumPropertyKindFilter | null;
}

export interface NestedEnumPropertyTypeFilter {
  equals?: PropertyType | null;
  in?: PropertyType[] | null;
  notIn?: PropertyType[] | null;
  not?: NestedEnumPropertyTypeFilter | null;
}

export interface NestedFloatFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatFilter | null;
}

export interface NestedFloatNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatNullableFilter | null;
}

export interface NestedIntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface NestedIntNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntNullableFilter | null;
}

export interface NestedStringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringFilter | null;
}

export interface NestedStringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringNullableFilter | null;
}

export interface PriceGroupWhereInput {
  AND?: PriceGroupWhereInput[] | null;
  OR?: PriceGroupWhereInput[] | null;
  NOT?: PriceGroupWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  xml_id?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringFilter | null;
  name?: StringFilter | null;
  base?: BoolFilter | null;
  sort?: IntFilter | null;
  PriceValue?: PriceValueListRelationFilter | null;
}

export interface PriceValueListRelationFilter {
  every?: PriceValueWhereInput | null;
  some?: PriceValueWhereInput | null;
  none?: PriceValueWhereInput | null;
}

export interface PriceValueWhereInput {
  AND?: PriceValueWhereInput[] | null;
  OR?: PriceValueWhereInput[] | null;
  NOT?: PriceValueWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  price?: DecimalFilter | null;
  priceWithDiscount?: DecimalFilter | null;
  priceDiscountValue?: DecimalFilter | null;
  priceDiscountPercent?: FloatFilter | null;
  canBuy?: BoolFilter | null;
  priceGroup_id?: StringFilter | null;
  priceGroup?: PriceGroupWhereInput | null;
  currency_id?: StringFilter | null;
  currency?: CurrencyWhereInput | null;
  element_id?: StringFilter | null;
  element?: ElementWhereInput | null;
}

export interface PropertyEnumListRelationFilter {
  every?: PropertyEnumWhereInput | null;
  some?: PropertyEnumWhereInput | null;
  none?: PropertyEnumWhereInput | null;
}

export interface PropertyEnumWhereInput {
  AND?: PropertyEnumWhereInput[] | null;
  OR?: PropertyEnumWhereInput[] | null;
  NOT?: PropertyEnumWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  xml_id?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  sort?: IntFilter | null;
  property_id?: StringNullableFilter | null;
  property?: PropertyWhereInput | null;
  elements?: ElementListRelationFilter | null;
  hlelements?: HlElementListRelationFilter | null;
  sections?: SectionListRelationFilter | null;
  value?: StringFilter | null;
  SettingPlus?: SettingPlusWhereInput | null;
  settingPlusId?: StringNullableFilter | null;
}

export interface PropertyRelationListRelationFilter {
  every?: PropertyRelationWhereInput | null;
  some?: PropertyRelationWhereInput | null;
  none?: PropertyRelationWhereInput | null;
}

export interface PropertyRelationWhereInput {
  AND?: PropertyRelationWhereInput[] | null;
  OR?: PropertyRelationWhereInput[] | null;
  NOT?: PropertyRelationWhereInput[] | null;
  id?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdAt?: DateTimeFilter | null;
  sort?: IntNullableFilter | null;
  property_id?: StringNullableFilter | null;
  property?: PropertyWhereInput | null;
  element_id?: StringNullableFilter | null;
  element_sort?: IntNullableFilter | null;
  element?: ElementWhereInput | null;
  hlelement_id?: StringNullableFilter | null;
  hlelement?: HlElementWhereInput | null;
  section_id?: StringNullableFilter | null;
  section?: SectionWhereInput | null;
  targetElement_id?: StringNullableFilter | null;
  targetElement?: ElementWhereInput | null;
  targetElements?: ElementListRelationFilter | null;
  targetSection_id?: StringNullableFilter | null;
  targetSection?: SectionWhereInput | null;
  targetSections?: SectionListRelationFilter | null;
  targetFile_id?: StringNullableFilter | null;
  targetFile?: FileWhereInput | null;
  targetFiles?: FileListRelationFilter | null;
  targetUser_id?: StringNullableFilter | null;
  targetUser?: UserWhereInput | null;
  targetUsers?: UserListRelationFilter | null;
  targetHlElement_id?: StringNullableFilter | null;
  targetHlElement?: HlElementWhereInput | null;
  targetHlElements?: HlElementListRelationFilter | null;
  userId?: StringNullableFilter | null;
}

export interface PropertyValueListRelationFilter {
  every?: PropertyValueWhereInput | null;
  some?: PropertyValueWhereInput | null;
  none?: PropertyValueWhereInput | null;
}

export interface PropertyValueWhereInput {
  AND?: PropertyValueWhereInput[] | null;
  OR?: PropertyValueWhereInput[] | null;
  NOT?: PropertyValueWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  sort?: IntNullableFilter | null;
  property_id?: StringNullableFilter | null;
  property?: PropertyWhereInput | null;
  element_id?: StringNullableFilter | null;
  element?: ElementWhereInput | null;
  section_id?: StringNullableFilter | null;
  section?: SectionWhereInput | null;
  hlelement_id?: StringNullableFilter | null;
  hlelement?: HlElementWhereInput | null;
  value?: StringNullableFilter | null;
  description?: StringNullableFilter | null;
  values?: StringNullableListFilter | null;
  descriptions?: StringNullableListFilter | null;
}

export interface PropertyWhereInput {
  AND?: PropertyWhereInput[] | null;
  OR?: PropertyWhereInput[] | null;
  NOT?: PropertyWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringNullableFilter | null;
  name?: StringFilter | null;
  active?: BoolFilter | null;
  sort?: IntFilter | null;
  kind?: EnumPropertyKindFilter | null;
  multiple?: BoolFilter | null;
  type?: EnumPropertyTypeFilter | null;
  values?: PropertyValueListRelationFilter | null;
  valuesEnum?: PropertyEnumListRelationFilter | null;
  valuesRelation?: PropertyRelationListRelationFilter | null;
  SkuIblock?: IblockListRelationFilter | null;
}

export interface SectionListRelationFilter {
  every?: SectionWhereInput | null;
  some?: SectionWhereInput | null;
  none?: SectionWhereInput | null;
}

export interface SectionOrderByInput {
  id?: SortOrder | null;
  bx_id?: SortOrder | null;
  xml_id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  code?: SortOrder | null;
  name?: SortOrder | null;
  active?: SortOrder | null;
  sort?: SortOrder | null;
  level?: SortOrder | null;
  description?: SortOrder | null;
  iblock_id?: SortOrder | null;
  previewPicture_id?: SortOrder | null;
  detailPicture_id?: SortOrder | null;
  seo_id?: SortOrder | null;
  searchableContent?: SortOrder | null;
  tags?: SortOrder | null;
  parentSection_id?: SortOrder | null;
}

export interface SectionWhereInput {
  AND?: SectionWhereInput[] | null;
  OR?: SectionWhereInput[] | null;
  NOT?: SectionWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntNullableFilter | null;
  xml_id?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  active?: BoolFilter | null;
  sort?: IntFilter | null;
  level?: IntFilter | null;
  description?: StringNullableFilter | null;
  iblock?: IblockWhereInput | null;
  iblock_id?: StringNullableFilter | null;
  previewPicture?: FileWhereInput | null;
  previewPicture_id?: StringNullableFilter | null;
  detailPicture?: FileWhereInput | null;
  detailPicture_id?: StringNullableFilter | null;
  seo_id?: StringNullableFilter | null;
  seo?: SeoWhereInput | null;
  searchableContent?: StringNullableFilter | null;
  tags?: StringNullableFilter | null;
  elements?: ElementListRelationFilter | null;
  ElementsToDirs?: ElementListRelationFilter | null;
  childElementsAll?: ElementListRelationFilter | null;
  childSections?: SectionListRelationFilter | null;
  parentSection_id?: StringNullableFilter | null;
  parentSection?: SectionWhereInput | null;
  childSectionsAll?: SectionListRelationFilter | null;
  propertyValues?: PropertyValueListRelationFilter | null;
  propertyValuesEnum?: PropertyEnumListRelationFilter | null;
  propertyValuesRelation?: PropertyRelationListRelationFilter | null;
  PropertyRelationsTargetSection?: PropertyRelationListRelationFilter | null;
  PropertyRelationTargetSections?: PropertyRelationListRelationFilter | null;
  SectionChildSections?: SectionListRelationFilter | null;
  SectionChildAllSections?: SectionListRelationFilter | null;
  SectionParentSection?: SectionListRelationFilter | null;
  ElementsParentSection?: ElementListRelationFilter | null;
  SettingPlusTargetSection?: SettingPlusListRelationFilter | null;
  SettingPlusTargetSections?: SettingPlusListRelationFilter | null;
}

export interface SeoWhereInput {
  AND?: SeoWhereInput[] | null;
  OR?: SeoWhereInput[] | null;
  NOT?: SeoWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  elementMetaDescription?: StringNullableFilter | null;
  elementMetaKeywords?: StringNullableFilter | null;
  elementMetaTitle?: StringNullableFilter | null;
  elementPageTitle?: StringNullableFilter | null;
  sectionMetaDescription?: StringNullableFilter | null;
  sectionMetaKeywords?: StringNullableFilter | null;
  sectionMetaTitle?: StringNullableFilter | null;
  sectionPageTitle?: StringNullableFilter | null;
  searchableContent?: StringNullableFilter | null;
  tags?: StringNullableFilter | null;
  element?: ElementWhereInput | null;
  section?: SectionWhereInput | null;
}

export interface SettingPlusListRelationFilter {
  every?: SettingPlusWhereInput | null;
  some?: SettingPlusWhereInput | null;
  none?: SettingPlusWhereInput | null;
}

export interface SettingPlusWhereInput {
  AND?: SettingPlusWhereInput[] | null;
  OR?: SettingPlusWhereInput[] | null;
  NOT?: SettingPlusWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringFilter | null;
  xml_id?: StringFilter | null;
  name?: StringFilter | null;
  sort?: IntFilter | null;
  multiple?: BoolFilter | null;
  type?: EnumPropertyTypeFilter | null;
  value?: StringNullableFilter | null;
  values?: StringNullableListFilter | null;
  valuesEnum?: PropertyEnumListRelationFilter | null;
  targetElement_id?: StringNullableFilter | null;
  targetElement?: ElementWhereInput | null;
  targetElements?: ElementListRelationFilter | null;
  targetSection_id?: StringNullableFilter | null;
  targetSection?: SectionWhereInput | null;
  targetSections?: SectionListRelationFilter | null;
  targetFile_id?: StringNullableFilter | null;
  targetFile?: FileWhereInput | null;
  targetFiles?: FileListRelationFilter | null;
}

export interface SettingPlusWhereUniqueInput {
  id?: string | null;
  bx_id?: number | null;
  code?: string | null;
}

export interface StringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringFilter | null;
}

export interface StringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringNullableFilter | null;
}

export interface StringNullableListFilter {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface UserListRelationFilter {
  every?: UserWhereInput | null;
  some?: UserWhereInput | null;
  none?: UserWhereInput | null;
}

export interface UserWhereInput {
  AND?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  NOT?: UserWhereInput[] | null;
  id?: StringFilter | null;
  bx_id?: IntFilter | null;
  xml_id?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  active?: BoolFilter | null;
  blocked?: BoolNullableFilter | null;
  login?: StringFilter | null;
  email?: StringNullableFilter | null;
  password?: StringFilter | null;
  fullName?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  surname?: StringNullableFilter | null;
  patronymic?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  provider_id?: StringNullableFilter | null;
  vk_id?: StringNullableFilter | null;
  fb_id?: StringNullableFilter | null;
  workPosition?: StringNullableFilter | null;
  gender?: StringNullableFilter | null;
  birthday?: DateTimeNullableFilter | null;
  country?: StringNullableFilter | null;
  area?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  street?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  groups?: GroupListRelationFilter | null;
  avatar_id?: StringNullableFilter | null;
  avatar?: FileWhereInput | null;
  wishlist?: ElementListRelationFilter | null;
  ElementCreatedBy?: ElementListRelationFilter | null;
  ElementModifiedBy?: ElementListRelationFilter | null;
  PropertyRelationTargetUser?: PropertyRelationListRelationFilter | null;
  PropertyRelationTargetUsers?: PropertyRelationListRelationFilter | null;
}

export interface VideoQualityListRelationFilter {
  every?: VideoQualityWhereInput | null;
  some?: VideoQualityWhereInput | null;
  none?: VideoQualityWhereInput | null;
}

export interface VideoQualityWhereInput {
  AND?: VideoQualityWhereInput[] | null;
  OR?: VideoQualityWhereInput[] | null;
  NOT?: VideoQualityWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  quality?: IntFilter | null;
  url?: StringFilter | null;
  size?: FloatFilter | null;
  width?: IntFilter | null;
  height?: IntFilter | null;
  duration?: FloatFilter | null;
  file_id?: StringNullableFilter | null;
  file?: FileWhereInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
