import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import css from './SearchField.module.scss'
import MaxWidth from '../../MaxWidth/MaxWidth'
import { LayoutContext } from '../../../../contexts/layoutProvider'
import cx from 'classnames'
import Link from 'next/link'
import CloseIcon from '../../../../public/static/icons/close.svg'
import SearchIcon from '../../../../public/static/icons/search.svg'

const SearchField: FC = () => {
  const { searchOpen, setSearchOpen, setMobileNavOpen } =
    useContext(LayoutContext)

  const refSearch = useRef()
  const refSearchField = useRef(null)
  const linkRef = useRef(null)
  const [href, setHref] = useState('')

  useEffect(() => {
    if (searchOpen) {
      refSearchField?.current?.focus()
    }
  }, [searchOpen])

  return (
    <div
      ref={refSearch}
      className={cx(css.container, searchOpen ? css.active : '')}
    >
      <MaxWidth>
        <div className={css.wrapper}>
          <label className={css.field}>
            <input
              className={css.input}
              placeholder={'Поиск'}
              ref={refSearchField}
              value={href}
              onChange={(e) => setHref(e.target.value)}
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  linkRef.current.click()
                }
              }}
            />
            <input
              className={cx(css.input, css.input_mobile)}
              placeholder={'Поиск по названию'}
              value={href}
              onChange={(e) => setHref(e.target.value)}
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  linkRef.current.click()
                }
              }}
            />
          </label>

          <Link href={`/search?q=${href}`} passHref>
            <a
              ref={linkRef}
              className={css.link}
              onClick={() => {
                setSearchOpen(false)
                setMobileNavOpen(false)
              }}
            >
              <span>искать</span>

              <SearchIcon />
            </a>
          </Link>

          <CloseIcon
            className={css.clear}
            onClick={() => {
              setHref('')
              setSearchOpen(false)
            }}
          />
        </div>
      </MaxWidth>
    </div>
  )
}

export default SearchField
