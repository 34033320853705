import React, { FC, useContext } from 'react'
import css from './HeaderButton.module.scss'
import Link from 'next/link'
import { HeaderColors } from '../../../types'
import { LayoutContext } from '../../../../contexts/layoutProvider'
import cx from 'classnames'
import { AuthContext } from '../../../../contexts/auth'
import { CartContext } from '../../../../contexts/cart'
import { WishlistContext } from '../../../../contexts/wishlist'

export interface IHeaderButton {
  code: string
  href: string
  title: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}
interface HeaderButton extends IHeaderButton {
  setAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCheckoutModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClick?: () => void
}

const HeaderButton: FC<HeaderButton> = ({
  code,
  href,
  title,
  Icon,
  setAuthModalOpen,
  setCheckoutModalOpen,
  onClick,
}) => {
  const {
    mobileNavOpen,
    searchOpen,
    setSearchOpen,
    setSecondNavOpen,
    headerColor,
    headerInitColor,
    setHeaderColor,
  } = useContext(LayoutContext)

  const { user, inited } = useContext(AuthContext)

  const { cart } = useContext(CartContext)

  const { elements } = useContext(WishlistContext)

  let handle = null
  if (code === 'search') {
    handle = () => {
      setSecondNavOpen(false)
      setHeaderColor(searchOpen ? headerInitColor : HeaderColors.light)
      setSearchOpen(!searchOpen)
    }
  }

  const notAuthorized = !user && code === 'personal'

  if (notAuthorized) {
    handle = () => {
      if (!inited) {
        return
      }
      setAuthModalOpen(true)
    }
  }

  const isCheckout = code === 'checkout'
  const total = cart?.info?.quantity

  if (isCheckout) {
    handle = () => {
      setCheckoutModalOpen(true)
    }
  }

  return handle ? (
    <>
      <button
        type="button"
        title={title}
        className={cx(
          css.link,
          code === 'search' && css.search,
          css[headerColor],
        )}
        onClick={handle}
      >
        <Icon className={cx(mobileNavOpen && css.active)} />
        {isCheckout && total > 0 && <span className={css.total}>{total}</span>}
      </button>
    </>
  ) : (
    <Link href={href} passHref>
      <a
        onClick={onClick}
        title={title}
        className={cx(css.link, css[headerColor])}
      >
        <Icon className={cx(mobileNavOpen && css.active)} />
        {code === 'favorites' && !!elements?.count && (
          <span className={css.total}>{elements.count}</span>
        )}
      </a>
    </Link>
  )
}

export default HeaderButton
