import gql from 'graphql-tag'

export const CART = gql`
  query cart {
    basket {
      info {
        price
        basePrice
        discountValue
        quantity
        freeDeliverUp
        freeDeliveryFrom
      }
      suggests {
        ...cartElement
      }
      giftsAll {
        name
        description
        priceFrom
        element {
          ...cartElement
        }
      }
      gifts {
        ...cartElement
      }
      coupons {
        coupon
        discountName
        active
        saved
        status
        statusText
      }
      items {
        id
        kind
        name
        price
        priceTotal
        basePriceTotal
        discountPrice
        quantity
        element {
          ...cartElement
        }
        props {
          name
          value
        }
      }
    }
  }

  fragment cartElement on Element {
    id
    name
    code
    previewText
    priceBase
    bx_id
    productType
    propertyValuesEnum {
      property {
        code
      }
      value
    }
    propertyValuesRelation {
      id
      property {
        id
        code
      }
      targetElement {
        id
        code
        name
        bx_id

        parentProduct {
          id
          code
          name

          parentSection {
            id
            code

            parentSection {
              id
              code
            }
          }
        }
      }
    }
    propertyValues(where: { property: { code: { equals: "GIFT_DESCR" } } }) {
      property {
        code
      }
      value
    }
    previewPicture {
      url
    }

    parentSection {
      id
      code
      name

      parentSection {
        id
        code
        name
      }
    }

    parentProduct {
      id
      code
      name

      parentSection {
        id
        code
        name
        active

        parentSection {
          id
          code
          name
        }
      }
    }
  }
`

export const CART_ADD = gql`
  mutation cartAdd($productId: String!, $quantity: Int, $token: String) {
    basketAddItem(productId: $productId, quantity: $quantity, token: $token) {
      success
      message
    }
  }
`

export const CART_ADD_SET = gql`
  mutation cartAddSet(
    $productId: String!
    $insetProductsIds: [String]
    $quantity: Int
    $token: String
  ) {
    basketAddSet(
      productId: $productId
      insetProductsIds: $insetProductsIds
      quantity: $quantity
      token: $token
    ) {
      success
      message
    }
  }
`

export const CART_REMOVE = gql`
  mutation cartRemove($recordId: Int!, $token: String) {
    basketRemoveItem(recordId: $recordId, token: $token) {
      success
      message
    }
  }
`

export const CART_SET_QUANTITY = gql`
  mutation cartSetQuantity($recordId: Int!, $quantity: Int!, $token: String) {
    basketSetQuantity(recordId: $recordId, quantity: $quantity, token: $token) {
      success
      message
    }
  }
`

export const CART_APPLY_COUPON = gql`
  mutation cartApplyCoupon($coupon: String!, $token: String) {
    basketApplyCoupon(coupon: $coupon, token: $token) {
      success
      message
    }
  }
`

export const CART_CLEAR_COUPON = gql`
  mutation cartClearCoupon($coupon: String!, $token: String) {
    basketClearCoupon(coupon: $coupon, token: $token) {
      success
      message
    }
  }
`
