import { useEffect, useState } from 'react'

interface ISizes {
  width: number
  height: number
  isMobile?: boolean
}

const MOBILE_WIDTH_LIMIT = 992

const useWindowSize = (): ISizes => {
  const [windowSize, setWindowSize] = useState<ISizes>({
    width: 0,
    height: 0,
    isMobile: false,
  })

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= MOBILE_WIDTH_LIMIT,
      })
    }

    handler()

    window.addEventListener('resize', handler)
    window.addEventListener('orientationchange', handler)

    return () => {
      window.removeEventListener('resize', handler)
      window.removeEventListener('orientationchange', handler)
    }
  }, [])

  return windowSize
}

export default useWindowSize
