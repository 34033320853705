import { useEffect, RefObject } from 'react'

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const el = ref?.current
      if (!el || el.contains(event.target as Node)) {
        return
      }
      handler(event)
    }

    const escapeListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        handler(event)
      }
    }

    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)
    document.addEventListener('keyup', escapeListener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [ref, handler])
}

export default useOnClickOutside
