import React, { FC } from 'react'
import css from './FieldWrapper.module.scss'
import cx from 'classnames'

interface IFieldWrapper {
  label?: string
  tooltip?: string
  errorMessage?: string
  className?: string
  errorClassName?: string
}
const FieldWrapper: FC<IFieldWrapper> = ({
  label,
  tooltip,
  errorMessage,
  children,
  className,
  errorClassName,
}) => {
  return (
    <label className={cx(css.wrapper, className)}>
      {label && <p className={css.label}>{label}</p>}
      {children}
      {errorMessage && (
        <p className={cx(css.error, errorClassName)}>{errorMessage}</p>
      )}
      {tooltip && <p className={css.tooltip}>{tooltip}</p>}
    </label>
  )
}

export default FieldWrapper
