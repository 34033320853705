import { IMoreBlock } from '../components/types'
import {
  getPropByCode,
  getPropDescriptionsByCode,
  getPropValuesByCode,
} from './gql'
import { moreQuery } from '../__generated__/moreQuery'

export const createMoreBlock = (data: moreQuery): IMoreBlock => {
  if (!data) {
    return null
  }

  const vals = getPropValuesByCode(data?.element?.propertyValues, 'SOCNETS')
  const descrs = getPropDescriptionsByCode(
    data?.element?.propertyValues,
    'SOCNETS',
  )

  const text = data?.element?.name
  const img = data?.element?.detailPicture?.url
  const link = getPropByCode(data?.element?.propertyValues, 'LINK_URL')
  const linkName = getPropByCode(data?.element?.propertyValues, 'LINK_TEXT')

  const socNets = vals?.map((val, index) => ({
    id: `soc_${val}`,
    link: val,
    code: descrs[index],
  }))

  return {
    text,
    img,
    link,
    linkName,
    socNets,
  }
}
