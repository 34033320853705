import gql from 'graphql-tag'

export const MORE_QUERY = gql`
  query moreQuery($where: ElementWhereInput!) {
    element(where: $where) {
      id
      name

      detailPicture {
        id
        url
      }

      propertyValues {
        property {
          id
          code
        }
        id
        value
        values
        descriptions
      }
    }
  }
`
