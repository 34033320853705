import React, { FC, useContext } from 'react'
import css from './SecondNavItem.module.scss'
import Link from 'next/link'
import { INavCategory } from '../../../../types'
import { LayoutContext } from '../../../../../contexts/layoutProvider'
import { useRouter } from 'next/router'
import cx from 'classnames'

const SecondNavItem: FC<INavCategory> = ({ title, items }: INavCategory) => {
  const { setSecondNavOpen, setHeaderColor, headerInitColor } =
    useContext(LayoutContext)
  const router = useRouter()
  return (
    <li
      className={css.container}
      role="navigation"
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      <dl>
        <dt className={css.title}>{title}</dt>
        <dd>
          <ul className={css.list}>
            {items?.map((item, index) => {
              return (
                <li className={css.item} key={index}>
                  <Link href={item.href} passHref>
                    <a
                      className={cx(
                        css.link,
                        router?.asPath?.includes(item.href) &&
                          item.href.length > 1 &&
                          css.active,
                      )}
                      itemProp="url"
                      title={item.title}
                      onClick={() => {
                        setSecondNavOpen(false)
                        setHeaderColor(headerInitColor)
                      }}
                    >
                      <span itemProp="name">{item.title}</span>
                    </a>
                  </Link>
                </li>
              )
            })}
          </ul>
        </dd>
      </dl>
    </li>
  )
}

export default SecondNavItem
