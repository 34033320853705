import React, { FC } from 'react'
import css from './KlbrLink.module.scss'
import KlbrIcon from '../../../../public/static/icons/klbr.svg'

const KlbrLink: FC = () => {
  return (
    <a
      href="https://kolibri.studio/"
      target="_blank"
      rel="noreferrer"
      className={css.link}
    >
      <span>Сделано в</span>
      <KlbrIcon />
    </a>
  )
}

export default KlbrLink
