import { useMutation, useQuery } from '@apollo/client'
import { createContext, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import debounce from 'lodash/debounce'
import {
  CART,
  CART_ADD,
  CART_ADD_SET,
  CART_APPLY_COUPON,
  CART_CLEAR_COUPON,
  CART_REMOVE,
  CART_SET_QUANTITY,
} from '../queries/cartQuery'
import {
  cart,
  cart_basket,
  cart_basket_coupons,
  cart_basket_items,
} from '../__generated__/cart'
import { cartAdd, cartAddVariables } from '../__generated__/cartAdd'
import { cartAddSet, cartAddSetVariables } from '../__generated__/cartAddSet'
import { cartRemove, cartRemoveVariables } from '../__generated__/cartRemove'
import {
  cartSetQuantity,
  cartSetQuantityVariables,
} from '../__generated__/cartSetQuantity'
import {
  cartApplyCoupon,
  cartApplyCouponVariables,
} from '../__generated__/cartApplyCoupon'
import {
  cartClearCoupon,
  cartClearCouponVariables,
} from '../__generated__/cartClearCoupon'
import { IYaEcommerceProduct } from '../components/types'

interface CartContext {
  cart?: cart_basket
  inited: boolean
  loading: boolean
  empty: boolean
  add: (
    productId: string,
    product: IYaEcommerceProduct,
    quantity?: number,
  ) => Promise<void>
  addSet: (
    productId: string,
    insetProductsIds: string[],
    product: IYaEcommerceProduct[],
    quantity?: number,
  ) => Promise<void>
  remove: (recordId: number) => Promise<void>
  setQuantity: (recordId: number, quantity: number) => Promise<void>
  applyCoupon: (coupon: string) => Promise<void>
  clearCoupon: (coupon: string) => Promise<void>
  updateCart: (action?: () => Promise<void>) => Promise<void>
  checkoutModalOpen: boolean
  setCheckoutModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setFrameRef: React.Dispatch<React.SetStateAction<HTMLIFrameElement>>
  addingProduct: string
  frameCoupons: cart_basket_coupons[]
  setFrameCoupons: React.Dispatch<React.SetStateAction<cart_basket_coupons[]>>
  frameBasketItems: cart_basket_items[]
  setFrameBasketItems: React.Dispatch<React.SetStateAction<cart_basket_items[]>>
}

export const CartContext = createContext({} as CartContext)

export const CartProvider: FC = ({ children }) => {
  const abortController = useRef<AbortController>()
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [inited, setInited] = useState(false)
  const activeRequests = useRef(0)
  const requestQueue = useRef<Promise<void>>()
  const [cart, setCart] = useState<cart_basket>()
  const [frameRef, setFrameRef] = useState<HTMLIFrameElement>(null)
  const [addingProduct, setAddingProduct] = useState('')
  const [frameCoupons, setFrameCoupons] = useState<cart_basket_coupons[]>([])
  const [frameBasketItems, setFrameBasketItems] = useState<cart_basket_items[]>(
    [],
  )

  useEffect(() => {
    if (frameRef) {
      frameRef?.contentWindow?.postMessage(
        'update',
        process.env.NEXT_PUBLIC_BITRIX_ENDPOINT,
      )
    }
  }, [cart])

  const { executeRecaptcha } = useGoogleReCaptcha()

  const { data: cartData, refetch } = useQuery<cart>(CART, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (inited || !cartData?.basket) {
      return
    }
    setLoading(false)
    setCart(cartData.basket)
    setInited(true)
  }, [cartData])

  const [cartAdd] = useMutation<cartAdd, cartAddVariables>(CART_ADD)
  const [cartAddSet] = useMutation<cartAddSet, cartAddSetVariables>(
    CART_ADD_SET,
  )
  const [cartRemove] = useMutation<cartRemove, cartRemoveVariables>(CART_REMOVE)
  const [cartSetQuantity] = useMutation<
    cartSetQuantity,
    cartSetQuantityVariables
  >(CART_SET_QUANTITY)
  const [applyCouponMutation] = useMutation<
    cartApplyCoupon,
    cartApplyCouponVariables
  >(CART_APPLY_COUPON)
  const [clearCouponMutation] = useMutation<
    cartClearCoupon,
    cartClearCouponVariables
  >(CART_CLEAR_COUPON)

  async function updateCart(action?: () => Promise<void>) {
    setLoading(true)
    activeRequests.current++

    const nextQuery = async () => {
      if (action) {
        await action()
      }

      if (activeRequests.current > 1) {
        activeRequests.current--
        return
      }

      const result = await refetch()

      activeRequests.current--

      if (activeRequests.current <= 0) {
        requestQueue.current = undefined
        if (result) {
          setCart(result.data.basket)
        }
        setLoading(false)
      }
    }

    if (requestQueue.current?.then) {
      requestQueue.current = requestQueue.current.then(nextQuery)
    } else {
      requestQueue.current = nextQuery()
    }
  }

  const abortLatest = () => {
    abortController.current && abortController.current.abort()
    requestQueue.current = undefined
    activeRequests.current = 0
    setLoading(false)
  }

  useEffect(() => {
    updateCart()
  }, [])

  async function getToken(action: string) {
    const token = await executeRecaptcha(action)
    return token
  }

  function updateCachedQuantity(id: number, quantity: number) {
    setCart({
      ...cart,
      items: cart.items.map((item) =>
        item.id === id
          ? {
            ...item,
            quantity,
          }
          : item,
      ),
    })
  }

  function deteleCachedItem(id: number) {
    setCart({
      ...cart,
      items: cart.items.filter((item) => item.id !== id),
    })
  }

  async function add(
    productId: string,
    product: IYaEcommerceProduct,
    quantity = 1,
  ) {
    updateCart(async () => {
      setAddingProduct(productId)
      const token = await getToken('cartAdd')
      const { data, errors } = await cartAdd({
        variables: {
          token,
          productId,
          quantity,
        },
      })
      setAddingProduct('')

      const error =
        errors?.[0] ||
        (!data?.basketAddItem?.success && data?.basketAddItem?.message)

      // if (window && !error) {
      //   const w = window as any

      //   w.dataLayer.push({
      //     ecommerce: {
      //       currencyCode: 'RUB',
      //       add: {
      //         products: [{ ...product }],
      //       },
      //     },
      //   })
      // }

      if (error) {
        console.error(error)
        return
      }
    })
  }

  async function addSet(
    productId: string,
    insetProductsIds: string[],
    products: IYaEcommerceProduct[],
    quantity = 1,
  ) {
    updateCart(async () => {
      const token = await getToken('cartAddSet')
      const { data, errors } = await cartAddSet({
        variables: {
          token,
          productId,
          insetProductsIds,
          quantity,
        },
      })

      const error =
        errors?.[0] ||
        (!data?.basketAddSet?.success && data?.basketAddSet?.message)

      if (window && !error) {
        const w = window as any

        w.dataLayer.push({
          ecommerce: {
            currencyCode: 'RUB',
            add: {
              products: [...products],
            },
          },
        })
      }

      if (error) {
        console.error(error)
        return
      }
    })
  }

  async function remove(recordId: number) {
    deteleCachedItem(recordId)

    updateCart(async () => {
      const token = await getToken('cartRemove')
      const { data, errors } = await cartRemove({
        variables: {
          token,
          recordId,
        },
      })

      const error =
        errors?.[0] ||
        (!data?.basketRemoveItem?.success && data?.basketRemoveItem?.message)

      if (error) {
        console.error(error)
        return
      }
    })
  }

  const debouncedUpdateCart = useMemo<
    (action?: () => Promise<void>) => Promise<void>
  >(
    () =>
      debounce(updateCart, 500, {
        leading: true,
      }),
    [],
  )

  async function setQuantity(recordId: number, quantity: number) {
    if (window) {
      const w = window as any
      const elem = cart.items.filter((it) => it.id === recordId)[0]
      const { quantity: prevQuantity } = elem

      if (elem) {
        const products = {
          id: elem.element?.bx_id,
          name: elem.name,
          price: elem.price,
          category: elem.element?.parentSection?.name
            ? elem.element?.parentSection?.name
            : elem?.element?.parentProduct?.parentSection?.name,
          quantity: 1,
        }

        const action =
          prevQuantity > quantity
            ? {
              remove: {
                products: [products],
              },
            }
            : {
              add: {
                products: [products],
              },
            }

        w.dataLayer.push({
          ecommerce: {
            currencyCode: 'RUB',
            ...action,
          },
        })
      }
    }
    abortLatest()
    updateCachedQuantity(recordId, quantity)
    debouncedUpdateCart(async () => {
      const controller = new window.AbortController()
      abortController.current = controller

      const token = await getToken('cartQuantity')
      const { data, errors } = await cartSetQuantity({
        variables: {
          token,
          recordId,
          quantity,
        },
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      })

      const error =
        errors?.[0] ||
        (!data?.basketSetQuantity?.success && data?.basketSetQuantity?.message)

      if (error) {
        console.error(error)
        return
      }
    })
  }

  async function applyCoupon(coupon: string) {
    updateCart(async () => {
      const token = await getToken('applyCoupon')
      const { data, errors } = await applyCouponMutation({
        variables: {
          token,
          coupon,
        },
      })

      const error =
        errors?.[0] ||
        (!data?.basketApplyCoupon?.success && data?.basketApplyCoupon?.message)

      if (error) {
        console.error(error)
        return
      }
    })
  }

  async function clearCoupon(coupon: string) {
    updateCart(async () => {
      const token = await getToken('clearCoupon')
      const { data, errors } = await clearCouponMutation({
        variables: {
          token,
          coupon,
        },
      })

      setFrameCoupons((prev) => {
        const newCoupons = prev.filter((c) => c.coupon !== coupon)

        return newCoupons
      })

      const error =
        errors?.[0] ||
        (!data?.basketClearCoupon?.success && data?.basketClearCoupon?.message)

      if (error) {
        console.error(error)
        return
      }
    })
  }

  const empty = inited && cart && cart.items && cart.items.length === 0

  return (
    <CartContext.Provider
      value={{
        cart,
        inited,
        loading,
        empty,
        add,
        addSet,
        remove,
        setQuantity,
        applyCoupon,
        clearCoupon,
        updateCart,
        checkoutModalOpen,
        setCheckoutModalOpen,
        setFrameRef,
        addingProduct,
        frameCoupons,
        setFrameCoupons,
        frameBasketItems,
        setFrameBasketItems,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
