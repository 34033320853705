import React from 'react'
import Link from 'next/link'
import cx from 'classnames'

import css from './Button.module.scss'
import { ButtonTheme } from '../../types'
import Loader from '../Loader/Loader'
import EllipseBorderSvg from '../../../public/static/icons/button-border.svg'

type ButtonType = 'button' | 'submit' | 'reset' | undefined

interface IButton {
  children?: React.ReactNode
  title?: string
  href?: string
  target?: string
  type?: ButtonType
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  theme?: ButtonTheme | undefined
  className?: string
  style?: React.CSSProperties
  loading?: boolean
}

const Button = React.forwardRef(function KMButton(
  props: IButton,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
) {
  const {
    children,
    title,
    href,
    target,
    type,
    disabled,
    theme,
    onClick,
    className,
    style,
    loading,
  } = props

  const getTheme = () => {
    switch (theme) {
      case ButtonTheme.black:
        return css.black
      case ButtonTheme.white:
        return css.white
      case ButtonTheme.ellipse:
        return css.ellipse
      case ButtonTheme.ellipseMedium:
        return css.ellipseMedium
      case ButtonTheme.ellipseBig:
        return css.ellipseBig
      default:
        return ''
    }
  }

  const cssClassName = cx(
    css.button,
    getTheme(),
    className,
    loading && css.loading,
  )

  const content = loading ? <Loader /> : children

  if (href) {
    const rel = target === '_blank' ? 'noreferrer' : undefined

    if (typeof href === 'string' && href.indexOf('http') === 0) {
      return (
        <a
          href={href}
          title={title}
          target={target}
          rel={rel}
          onClick={onClick}
          style={style}
          className={cssClassName}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          <EllipseBorderSvg className={css.ellipseBorder} />
          {content}
        </a>
      )
    } else {
      return (
        <Link href={href} passHref>
          <a
            title={title}
            target={target}
            rel={rel}
            onClick={onClick}
            style={style}
            className={cssClassName}
            ref={ref as React.Ref<HTMLAnchorElement>}
          >
            <EllipseBorderSvg className={css.ellipseBorder} />
            {content}
          </a>
        </Link>
      )
    }
  }

  return (
    <button
      type={type}
      title={title}
      onClick={onClick}
      disabled={disabled}
      style={style}
      className={cssClassName}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      <EllipseBorderSvg className={css.ellipseBorder} />

      {content}
    </button>
  )
})

export default Button
