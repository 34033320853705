import React, { FC } from 'react'
import css from './Controls.module.scss'
import HeaderButton from '../HeaderButton/HeaderButton'
import { headerButtons } from '../../../data'
import cx from 'classnames'

interface Controls {
  setAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCheckoutModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClick?: () => void
  withJulirea?: boolean
}
const Controls: FC<Controls> = ({
  setAuthModalOpen,
  setCheckoutModalOpen,
  onClick,
  withJulirea,
}) => {
  return (
    <div className={cx(css.container, withJulirea && css.container__jul)}>
      <div className={css.wrapper}>
        {headerButtons.map((button) => {
          return (
            <HeaderButton
              key={button.code}
              onClick={onClick}
              setAuthModalOpen={setAuthModalOpen}
              setCheckoutModalOpen={setCheckoutModalOpen}
              {...button}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Controls
