import React, { FC, useContext } from 'react'
import css from './MainNav.module.scss'
import Link from 'next/link'
import cx from 'classnames'

import { LayoutContext } from '../../../../contexts/layoutProvider'
import { useRouter } from 'next/router'

const MainNav: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { mainNav, headerColor } = useContext(LayoutContext)
  const router = useRouter()

  return (
    <nav
      className={css.container}
      role="navigation"
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      <ul className={css.list}>
        {mainNav
          ?.filter((item) => item.code != 'detskaya_mineralnaya_kosmetika')
          ?.map((item) => {
            return (
              <li className={css.item} key={item.code}>
                <Link href={item.href} as={item.as} passHref>
                  <a
                    className={cx(
                      css.link,
                      css[headerColor],
                      (router?.asPath === item.as ||
                        router?.asPath?.includes(item.as)) &&
                        css.active,
                    )}
                    itemProp="url"
                    title={item.title}
                    onClick={onClick}
                  >
                    <span itemProp="name">{item.title}</span>
                  </a>
                </Link>
              </li>
            )
          })}
      </ul>
    </nav>
  )
}

export default MainNav
