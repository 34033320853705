import { FC, useContext } from 'react'
import cx from 'classnames'
import Link from 'next/link'

import Button from '../../common/Button/Button'
import styles from './AsideBottom.module.scss'
import { CartContext } from '../../../contexts/cart'
import { formatPrice } from '../../../lib/utils'

interface AsideBottom {
  onClose: () => void
}
const AsideBottom: FC<AsideBottom> = ({ onClose }) => {
  const { cart, loading } = useContext(CartContext)

  return (
    <div className={styles.wrapper}>
      <table className={cx(styles.table, loading && styles.loading)}>
        <tbody>
          <tr>
            <td>Итого:</td>
            <td>{formatPrice(cart?.info?.price)}</td>
          </tr>
        </tbody>
      </table>
      <Link href="/checkout" passHref>
        <Button className={styles.button} onClick={onClose}>
          ПЕРЕЙТИ К ОФОРМЛЕНИЮ
        </Button>
      </Link>
    </div>
  )
}

export default AsideBottom
