import React, { FC, useEffect, useState } from 'react'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import css from './Cookies.module.scss'

const Cookies: FC = () => {
  const [accept, setAccept] = useLocalStorage('accept-cookies', false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (!accept) {
      setTimeout(() => setActive(true), 1000)
    }
  }, [accept])
  return (
    <div className={`${css.wrapper} ${active ? css.active : ''}`}>
      <MaxWidth>
        <div className={css.inner}>
          <p className={css.text}>
            «KM cosmetics» использует файлы «cookie» с целью персонализации
            сервисов и повышения удобства пользования веб-сайтом. Если Вы не
            хотите, чтобы Ваши пользовательские данные обрабатывались,
            пожалуйста, ограничьте их использование в своём браузере
          </p>
          <button
            onClick={() => {
              setActive(false)
              setAccept(true)
            }}
            type="button"
            className={css.button}
          >
            хорошо
          </button>
        </div>
      </MaxWidth>
    </div>
  )
}

export default Cookies
