import {
  quiz_result_element_propertyValuesRelation_targetElement,
  quiz_result_element_propertyValuesRelation_targetElements,
} from '../__generated__/quiz_result'

export enum ButtonTheme {
  black = 'black',
  white = 'white',
  ellipse = 'ellipse',
  ellipseMedium = 'ellipse-medium',
  ellipseBig = 'ellipse-big',
}

export enum HeaderColors {
  black = 'black',
  white = 'white',
  light = 'light',
  fixedTransparent = 'fixedTransparent',
}

export enum HeaderTextColors {
  init = 'init',
  white = 'white',
}

export type SocNets = 'facebook' | 'vk' | 'youtube' | 'tg' | 'tiktok' | 'dzen'

export interface IPageSeo {
  title: string
  description: string
  keywords?: string
}
export interface INavItem {
  code: string
  href?: string
  as?: string
  title: string
  selected?: boolean
  onClick?: () => void
}

export interface INavCategory {
  code: string
  title: string
  href?: string
  as?: string
  items?: INavItem[]
}

export interface IProduct {
  name: string
  href: string
}

export interface IReview {
  id: string
  name: string
  product?: IProduct
  rate: number
  text: string
  date: string
}

export interface ILookBookItem {
  img: string
  name: string
  link: string
  id: string
}

export interface ILookProduct {
  name: string
  subName?: string
  id: string
  bx_id: number
  price: number
  priceOld?: number
  link: string
}

export interface ILookDetail {
  id: string
  name: string
  img: string
  structure: ILookProduct[]
  journal: ILookJournalPost
}

export interface ILookJournalPost {
  name: string
  videoLink?: string
  postLink?: string
}

export interface IAccordionText {
  title: string
  description: string
  id: string
}

export interface IParamPick {
  id: string
  bx_id: number
  item: string
  image: string
  artNumber?: string
  priceOld: number
  price: number
}

export interface IProductSlide {
  id: string
  img: string
  name: string
}

export interface IProductQuote {
  img: string
  quote: string
  author: IAuthor
  source?: string
}

export interface IProductDetail {
  id: string
  bx_id: number
  img: string
  title: string
  subtitle?: string
  description?: string
  colors?: IParamPick[]
  aromates?: IParamPick[]
  price: number
  oldPrice: number
  rate?: number
  reviewCount?: number
  accordions?: IAccordionText[]
  recomended?: ICatalogItem[]
  subsets?: IProductDetail[]
  quote?: IProductQuote
  external?: {
    description: string
    value: string
  }[]
}

export interface IStoryImage {
  url: string
  id: string
}

export interface IStories {
  id: string
  img?: string
  detailImg: IStoryImage[]
  title: string
}
export interface IQuestionVariant {
  title?: string
  description?: string
  img: string
  tooltip?: string
  id: number
}

export interface IQuestion {
  question: string
  variants: IQuestionVariant[]
}

export interface IQuizResult {
  title: string
  description?: string
  img: string
  probePrice?: string
  hasProbe?: boolean
  href: string
  product: quiz_result_element_propertyValuesRelation_targetElements
  probe: quiz_result_element_propertyValuesRelation_targetElement
}

export interface IJournalItem {
  heading: string
  announcement: string
  category: string
  link: string
  imgSrc: string
  new?: boolean
}

export interface IJournalYouTubeItem {
  heading: string
  link: string
  imgSrc: string
}

export interface IJournalPost {
  title: string
  cover: string
  date: string
  category: string
  post: string
  link: string
}

export interface IIGData {
  photo: string
  link: string
  code: string
}

export interface ICoords {
  lat: string
  lng: string
}
export interface IShop {
  code: string
  name: string
  address?: string
  phone?: string
  mail?: string
  site?: string
  coords?: ICoords
}

export interface ICity {
  code: string
  name: string
  coords: ICoords
  shops: IShop[]
}

export interface ICatalogItem {
  id: string
  bx_id: number
  name: string
  code: string
  img: string
  newProduct: boolean
  kids: boolean
  leader: boolean
  action: boolean
  hasIcon: boolean
  descr: string
  hoverImage: string
  price: number
  priceOld: number
  link?: string
  hasSkus?: boolean
  hoverText?: string
  isJulirea?: boolean
  section?: string
  isAllSkusSale?: boolean
  isAllSkuSalesSimilar?: boolean
}
export type Tab = 'list' | 'map' | undefined

export interface IBreadcrumb {
  name: string
  link: string
  id: string
}

export interface IAboutPageContent {
  code: string
  contents: string
}

export interface IAboutPage {
  code: string
  contents: IAboutPageContent[]
}

export interface IInfoPageContent {
  code: string
  description: string
}

export interface IInfoPage {
  code: string
  contents: IInfoPageContent[]
}

export interface IMainSliderSlide {
  code: string
  img: string
  imgMobile: string
  title?: string
  description: string
  buttonText?: string
  buttonLink?: string
  buttonColor?: string
  textAlign?: string
  textColor?: string
  headerColor?: string
  slideLink?: string
}

export interface IAuthor {
  img: string
  description: string
}

export interface IVideoSlide {
  id: string
  video: string
  quote: string
  author: IAuthor
}

export interface IWorkHours {
  day: string
  hours: string
}

export interface IContacts {
  address: string
  phone: string
  email: string
  coords: number[]
  index: string
  workHours: IWorkHours[]
  wa: string
  tg: string
}

export interface IPlayer {
  video: string
  poster: string
  title: string
  description?: string
}

export interface IMainPageCategory {
  title: string
  description?: string
  img?: string
  elements: ICatalogItem[]
  icons: IMainIcons[]
}

export interface IAdvantage {
  id: string
  description: string
  code: string
  img: string
}

export interface IMainQuote {
  text: string
  img: string
  post: string
  name: string
  bigImg?: string
}

export interface IMainFirstLink {
  link: string
  title: string
  img: string
}

export interface IMainPageFirstTextBlock {
  title: string
  advantages: IAdvantage[]
  quote: IMainQuote
  link: IMainFirstLink
}

export interface IMainPagePlate {
  id: string
  smallText: string
  bigText: string
  linkName: string
  link: string
  img: string
}

export interface IMainPageTextBlocks {
  first: IMainPageFirstTextBlock
  plates: IMainPagePlate[]
}

export interface IMainIcons {
  id: string
  img: string
  descr: string
}
export interface IMainBeforeAndAfter {
  title: string
  description: string
  before: string
  after: string
  link: string
  linkImg: string
  icons: IMainIcons[]
}

export interface IMoreBlock {
  img: string
  text?: string
  link: string
  linkName: string
  socNets: ISocNets[]
}

export interface IWholeSalePlates {
  id: string
  img: string
  title: string
  description?: string
}
export interface IWholesalePage {
  title: string
  description?: string
  plates?: IWholeSalePlates[]
}

export interface IImageTickerItem {
  id: string
  img: string
  link?: string
}

export interface IJulireaSection {
  id: string
  title: string
  code: string
  elements: ICatalogItem[]
  img?: string
}

export interface IJulireaNavItem {
  id: string
  title: string
  code: string
}

export interface IYaEcommerceProduct {
  id: number
  name: string
  category?: string
  quantity?: number
  price?: number
}

export interface ISocNets {
  id: string
  link: string
  code: string
}
