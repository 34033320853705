import React, { FC } from 'react'
import css from './AgreementLink.module.scss'

const AgreementLink: FC = () => {
  return (
    <a
      href="/info/agreement"
      target="_blank"
      className={css.link}
      title="Пользовательское соглашение"
    >
      Пользовательское соглашение
    </a>
  )
}

export default AgreementLink
