import React, { FC } from 'react'
import LayoutProvider from '../../../contexts/layoutProvider'
import css from './Layout.module.scss'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Body from '../Body/Body'
import Cookies from '../../common/Cookies/Cookies'
import { HeaderColors } from '../../types'
import cx from 'classnames'
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage'

interface ILayout {
  children?: React.ReactNode
  headerInitColor: HeaderColors
  showInnerNav?: boolean
  hideBorder?: boolean
  errorPage?: boolean
}

const Layout: FC<ILayout> = ({
  children,
  headerInitColor,
  showInnerNav,
  hideBorder,
  errorPage,
}: ILayout) => {
  return (
    <LayoutProvider
      showInnerNav={showInnerNav}
      headerInitColor={headerInitColor}
      hideBorder={hideBorder}
    >
      <div className={cx(css.wrapper, errorPage && css.errorPage)}>
        <Header />
        <Body>{children}</Body>
        <Footer />
      </div>
      <Cookies />

      <ErrorMessage />
    </LayoutProvider>
  )
}

export default Layout
