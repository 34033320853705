import sanitizeHtml from 'sanitize-html'

export const declOfNum = (number: number, titles: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ]
}

export const getImageSrc = (filename: string): string => {
  if (!filename) {
    return ''
  }

  return filename
    .replace(
      process.env.NEXT_PUBLIC_DOMAIN_ENDPOINT,
      process.env.NEXT_PUBLIC_BITRIX_ENDPOINT,
    )
    .startsWith('http')
    ? filename
    : `${process.env.NEXT_PUBLIC_BITRIX_ENDPOINT}${filename}`
}

export const getOptimizedImageSrc = (
  filename: string,
  quality?: number,
): string => {
  if (!filename) {
    return ''
  }

  return `${process.env.NEXT_PUBLIC_IMAGE_OPTIMIZER}/rs:fill/q:${
    quality ? quality : 90
  }/plain/${getImageSrc(filename)}`
}
export const clearHtml = (html: string, additionalTags?: string[]): string => {
  let _html = html

  if (!_html) {
    return html
  }

  const options = {
    allowedTags: [
      'address',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'hr',
      'li',
      'ol',
      'p',
      'pre',
      'ul',
      'a',
      'b',
      'br',
      'cite',
      'em',
      'i',
      'small',
      'span',
      'strong',
      'sub',
      'sup',
      'u',
      'wbr',
      'col',
      'colgroup',
      'table',
      'tbody',
      'td',
      'tfoot',
      'th',
      'thead',
      'tr',
      'img',
      'figure',
      'figcaption',
      'div',
      'svg',
      'circle',
      'path',
      'section',
      'label',
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target', 'rel', 'title', 'download', 'class'],
      img: ['src', 'alt'],
      ul: ['class'],
      ol: ['class'],
      p: ['class'],
      b: ['class'],
      span: ['class'],
      svg: ['*'],
      circle: ['*'],
      path: ['*'],
      label: ['class'],
    },
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'viber'],
    transformTags: {
      img: (tagName, attrs) => {
        attrs.src = getOptimizedImageSrc(attrs.src)
        return { tagName: 'img', attribs: attrs }
      },
    },
  }

  if (additionalTags?.length > 0) {
    options.allowedTags.push(...additionalTags)
  }

  _html = sanitizeHtml(_html, options) || ''

  return _html
}

export const getDateString = (date: Date): string => {
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ]

  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
}

export const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = 'height, margin, padding'
  target.style.transitionDuration = duration + 'ms'
  target.style.boxSizing = 'border-box'
  target.style.height = target.offsetHeight + 'px'
  target.offsetHeight
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  window.setTimeout(() => {
    target.style.display = 'none'
    target.style.removeProperty('height')
    target.style.removeProperty('padding-top')
    target.style.removeProperty('padding-bottom')
    target.style.removeProperty('margin-top')
    target.style.removeProperty('margin-bottom')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
  }, duration)
}

export const slideDown = (target, duration = 500) => {
  target.style.removeProperty('display')
  let display = window.getComputedStyle(target).display
  if (display === 'none') display = 'block'
  target.style.display = display
  const height = target.offsetHeight
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.offsetHeight
  target.style.boxSizing = 'border-box'
  target.style.transitionProperty = 'height, margin, padding'
  target.style.transitionDuration = duration + 'ms'
  target.style.height = height + 'px'
  target.style.removeProperty('padding-top')
  target.style.removeProperty('padding-bottom')
  target.style.removeProperty('margin-top')
  target.style.removeProperty('margin-bottom')
  window.setTimeout(() => {
    target.style.removeProperty('height')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
  }, duration)
}

export const formatPrice = (price: number): string => {
  if (price === null || price === undefined) {
    return ''
  }
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`
}
