import gql from 'graphql-tag'

export const ELEMENT_SEO_FIELDS = gql`
  fragment elementSeoFields on Element {
    seo {
      elementMetaTitle
      elementMetaDescription
      elementMetaKeywords
    }
  }
`
export const SECTION_SEO_FIELDS = gql`
  fragment sectionSeoFields on Section {
    seo {
      sectionMetaTitle
      sectionMetaDescription
      sectionMetaKeywords
    }
  }
`

export const SEO = gql`
  query seo($where: ElementWhereInput!) {
    element(where: $where) {
      propertyValues {
        property {
          id
          code
        }
        id
        value
      }
    }
  }
`
