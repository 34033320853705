import React, { FC, createContext, useState } from 'react'
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client'
import { user } from '../__generated__/user'
import { me } from '../__generated__/me'
import { signup, signupVariables, signup_signup } from '../__generated__/signup'
import { signin, signinVariables, signin_signin } from '../__generated__/signin'
import { logout, logoutVariables, logout_logout } from '../__generated__/logout'
import {
  recovery,
  recoveryVariables,
  recovery_recoveryPassword,
} from '../__generated__/recovery'
import {
  change,
  changeVariables,
  change_changePassword,
} from '../__generated__/change'
import { updateInfo, updateInfoVariables } from '../__generated__/updateInfo'
import {
  updateContact,
  updateContactVariables,
} from '../__generated__/updateContact'
import {
  updatePassword,
  updatePasswordVariables,
} from '../__generated__/updatePassword'
import {
  userUpdatePersonalBirthday,
  userUpdatePersonalBirthdayVariables,
} from '../__generated__/userUpdatePersonalBirthday'
import {
  LOGOUT,
  ME,
  SIGNIN,
  SIGNUP,
  RECOVERY,
  CHANGE,
  UPDATE_INFO,
  UPDATE_CONTACT,
  UPDATE_PASSWORD,
  UPDATE_BIRTHDAY,
} from '../queries/authQuery'
import { useRouter } from 'next/router'

interface IAuthContext {
  inited: boolean
  user?: user
  refetch: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<me>>
  signup: (variables: signupVariables) => Promise<signup_signup>
  signin: (variables: signinVariables) => Promise<signin_signin>
  logout: (variables: logoutVariables) => Promise<logout_logout>
  recovery: (variables: recoveryVariables) => Promise<recovery_recoveryPassword>
  change: (variables: changeVariables) => Promise<change_changePassword>
  updateInfo: (variables: updateInfoVariables) => Promise<updateInfo>
  updateContact: (variables: updateContactVariables) => Promise<updateContact>
  updatePassword: (
    variables: updatePasswordVariables,
  ) => Promise<updatePassword>
  updateBirtday: (
    variables: userUpdatePersonalBirthdayVariables,
  ) => Promise<void>
  signupLoading: boolean
  signinLoading: boolean
  logoutLoading: boolean
  recoveryLoading: boolean
  changeLoading: boolean
  updateInfoLoading: boolean
  updateContactLoading: boolean
  updatePasswordLoading: boolean
  updateBirthdayLoading: boolean
  authModalOpen: boolean
  setAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isAdmin: boolean
}

export const AuthContext = createContext({} as IAuthContext)

export const AuthProvider: FC = ({ children }) => {
  const [authModalOpen, setAuthModalOpen] = useState(false)
  const [inited, setInited] = useState(false)
  const [user, setUser] = useState<user>()
  const [isAdmin, setIsAdmin] = useState(false)
  const { replace } = useRouter()

  const { refetch } = useQuery<me>(ME, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.me?.success) {
        setUser(data.me.user)

        setIsAdmin(
          data?.me?.user?.groups?.findIndex((group) => group.bx_id === 1) > -1,
        )
      }
      setTimeout(() => {
        setInited(true)
      }, 0)
    },
  })

  const [signupMutation, { loading: signupLoading }] = useMutation<
    signup,
    signupVariables
  >(SIGNUP, {
    notifyOnNetworkStatusChange: true,
  })

  async function signup(variables: signupVariables): Promise<signup_signup> {
    const { data, errors } = await signupMutation({
      variables,
    })

    if (errors) {
      return
    }

    setUser(data?.signup?.user)
    setIsAdmin(
      data?.signup?.user?.groups?.findIndex((group) => group.bx_id === 1) > -1,
    )
    return data.signup
  }

  const [signinMutation, { loading: signinLoading }] = useMutation<
    signin,
    signinVariables
  >(SIGNIN, {
    notifyOnNetworkStatusChange: true,
  })

  async function signin(variables: signinVariables): Promise<signin_signin> {
    const { data, errors } = await signinMutation({
      variables,
    })

    if (errors) {
      return
    }

    setUser(data?.signin?.user)
    setIsAdmin(
      data?.signin?.user?.groups?.findIndex((group) => group.bx_id === 1) > -1,
    )
    return data.signin
  }

  const [logoutMutation, { loading: logoutLoading }] = useMutation<
    logout,
    logoutVariables
  >(LOGOUT, {
    notifyOnNetworkStatusChange: true,
  })

  async function logout(variables: logoutVariables): Promise<logout_logout> {
    replace('/')

    const { data, errors } = await logoutMutation({
      variables,
    })

    if (errors) {
      return
    }

    setUser(undefined)
    setIsAdmin(false)

    return data.logout
  }

  const [recoveryMutation, { loading: recoveryLoading }] = useMutation<
    recovery,
    recoveryVariables
  >(RECOVERY, {
    notifyOnNetworkStatusChange: true,
  })

  async function recovery(
    variables: recoveryVariables,
  ): Promise<recovery_recoveryPassword> {
    const { data, errors } = await recoveryMutation({
      variables,
    })

    if (errors) {
      return
    }

    return data.recoveryPassword
  }

  const [changeMutation, { loading: changeLoading }] = useMutation<
    change,
    changeVariables
  >(CHANGE, {
    notifyOnNetworkStatusChange: true,
  })

  async function change(
    variables: changeVariables,
  ): Promise<change_changePassword> {
    const { data, errors } = await changeMutation({
      variables,
    })

    if (errors) {
      return
    }

    return data.changePassword
  }

  const [updateInfoMutation, { loading: updateInfoLoading }] = useMutation<
    updateInfo,
    updateInfoVariables
  >(UPDATE_INFO, {
    notifyOnNetworkStatusChange: true,
  })

  async function updateInfo(
    variables: updateInfoVariables,
  ): Promise<updateInfo> {
    const { data, errors } = await updateInfoMutation({
      variables,
    })

    const user = data?.userUpdatePersonalInfo?.user

    if (errors || !user) {
      return
    }

    setUser(user)
    setIsAdmin(user?.groups?.findIndex((group) => group.bx_id === 1) > -1)

    return data
  }

  const [updateContactMutation, { loading: updateContactLoading }] =
    useMutation<updateContact, updateContactVariables>(UPDATE_CONTACT, {
      notifyOnNetworkStatusChange: true,
    })

  async function updateContact(
    variables: updateContactVariables,
  ): Promise<updateContact> {
    const { data, errors } = await updateContactMutation({
      variables,
    })

    const user = data?.userUpdateContactInfo?.user

    if (errors || !user) {
      return
    }

    setUser(user)
    setIsAdmin(user?.groups?.findIndex((group) => group.bx_id === 1) > -1)

    return data
  }

  const [updatePasswordMutation, { loading: updatePasswordLoading }] =
    useMutation<updatePassword, updatePasswordVariables>(UPDATE_PASSWORD, {
      notifyOnNetworkStatusChange: true,
    })

  async function updatePassword(
    variables: updatePasswordVariables,
  ): Promise<updatePassword> {
    const { data, errors } = await updatePasswordMutation({
      variables,
    })

    if (errors) {
      return
    }

    return data
  }

  const [updateBirthdayMutation, { loading: updateBirthdayLoading }] =
    useMutation<
      userUpdatePersonalBirthday,
      userUpdatePersonalBirthdayVariables
    >(UPDATE_BIRTHDAY)

  const updateBirtday = async (
    variables: userUpdatePersonalBirthdayVariables,
  ) => {
    const { data, errors } = await updateBirthdayMutation({ variables })

    if (errors || !data?.userUpdatePersonalBirthday.user) {
      return
    }

    setUser(data?.userUpdatePersonalBirthday.user)
    setIsAdmin(
      data?.userUpdatePersonalBirthday?.user?.groups?.findIndex(
        (group) => group.bx_id === 1,
      ) > -1,
    )
  }

  return (
    <AuthContext.Provider
      value={{
        inited,
        user,
        refetch,
        signup,
        signin,
        logout,
        recovery,
        change,
        updateInfo,
        updateContact,
        updatePassword,
        updateBirtday,
        signupLoading,
        signinLoading,
        logoutLoading,
        recoveryLoading,
        changeLoading,
        updateInfoLoading,
        updateContactLoading,
        updatePasswordLoading,
        updateBirthdayLoading,
        authModalOpen,
        setAuthModalOpen,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
