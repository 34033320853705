import { useMutation, useQuery } from '@apollo/client'
import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react'
import {
  WISHLIST,
  WISHLIST_ITEMS,
  WISHLIST_ADD,
  WISHLIST_REMOVE,
  WISHLIST_RANDOM,
} from '../queries/wishlistQuery'
import { AuthContext } from './auth'
import {
  wishlist,
  wishlistVariables,
  wishlist_wishlist,
  wishlist_wishlist_edges,
} from '../__generated__/wishlist'
import { wishlistAdd, wishlistAddVariables } from '../__generated__/wishlistAdd'
import {
  wishlistItems,
  wishlistItemsVariables,
  wishlistItems_elements,
} from '../__generated__/wishlistItems'
import {
  wishlistRemove,
  wishlistRemoveVariables,
} from '../__generated__/wishlistRemove'
import {
  wishlistRandom,
  wishlistRandom_wishlistRandom,
} from '../__generated__/wishlistRandom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export const ELEMENTS_ONPAGE = 9

type Wishlist = Record<string, boolean>

interface WishlistContext {
  inited: boolean
  loading: boolean
  wishlist: Wishlist
  randomElements?: wishlistRandom_wishlistRandom[]
  addToWishlist: (id: string) => Promise<void>
  removeFromWishlist: (id: string) => Promise<void>
  list: wishlist_wishlist
  elements: wishlistItems_elements
  elementsLoading: boolean
}

export const WishlistContext = createContext({} as WishlistContext)

export const WishlistProvider: FC = ({ children }) => {
  const [inited, setInited] = useState(false)
  const { user, inited: authInited } = useContext(AuthContext)
  const [wishlist, setWishlist] = useState<Wishlist>({})
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { data, loading, refetch } = useQuery<wishlist, wishlistVariables>(
    WISHLIST,
    {
      variables: {
        first: 1000,
      },
    },
  )

  const list = data?.wishlist

  const wishlistIds = Object.keys(wishlist)

  const {
    data: elementsData,
    loading: elementsLoading,
    refetch: refetchItems,
  } = useQuery<wishlistItems, wishlistItemsVariables>(WISHLIST_ITEMS, {
    variables: {
      where: {
        id: {
          in: wishlistIds,
        },
      },
    },
    skip: !wishlistIds?.length,
  })

  const empty = inited && list?.edges?.length === 0

  const { data: randomData } = useQuery<wishlistRandom>(WISHLIST_RANDOM, {
    skip: !empty,
  })
  const randomElements = randomData?.wishlistRandom

  const elements = elementsData?.elements

  const [wishlistAdd] = useMutation<wishlistAdd, wishlistAddVariables>(
    WISHLIST_ADD,
  )
  const [wishlistRemove] = useMutation<wishlistRemove, wishlistRemoveVariables>(
    WISHLIST_REMOVE,
  )

  useEffect(() => {
    if (loading) {
      return
    }

    if (data?.wishlist?.edges?.length) {
      setWishlist({
        ...wishlist,
        ...getWishlistFromEdges(data?.wishlist?.edges),
      })
    } else {
      const localWishlist = getLocalWishlist()
      setWishlist(localWishlist || {})
    }

    setInited(true)
  }, [data, loading, inited])

  useEffect(() => {
    if (!inited) {
      return
    }
    setInited(false)
    setTimeout(() => {
      refetch()
      refetchItems()
    }, 0)
  }, [user])

  useEffect(() => {
    const localWishlist = getLocalWishlist()

    if (!localWishlist || !user) {
      return
    }

    fromLocalStorageToWishlist()

    async function fromLocalStorageToWishlist() {
      const token = await executeRecaptcha()
      const ids = Object.keys(localWishlist)
      const { data } = await wishlistAdd({
        variables: {
          ids,
          token,
        },
      })

      if (data.wishlistAdd) {
        removeLocalWishlist()
      }
    }
  }, [user])

  async function addToWishlist(id: string) {
    if (!authInited) {
      return
    }

    const newWishlist = { ...wishlist, [id]: true }
    setWishlist(newWishlist)

    if (user) {
      const token = await executeRecaptcha()
      await wishlistAdd({
        variables: {
          token,
          ids: [id],
        },
      })
      refetch()
    } else {
      setLocalWishlist(newWishlist)
    }
  }

  async function removeFromWishlist(id: string) {
    const newWishlist = { ...wishlist }

    if (!authInited || !newWishlist[id]) {
      return
    }

    delete newWishlist[id]

    setWishlist(newWishlist)

    if (user) {
      const token = await executeRecaptcha()
      await wishlistRemove({
        variables: {
          token,
          ids: [id],
        },
      })
      refetch()
    } else {
      setLocalWishlist(newWishlist)
    }
  }

  return (
    <WishlistContext.Provider
      value={{
        inited,
        wishlist,
        randomElements,
        addToWishlist,
        removeFromWishlist,
        list,
        loading,
        elements,
        elementsLoading: elementsLoading,
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}

function getLocalWishlist(): Wishlist {
  try {
    const json = localStorage.getItem('wishlist')
    return JSON.parse(json)
  } catch (error) {
    return
  }
}

function setLocalWishlist(wishlist: Wishlist) {
  try {
    const json = JSON.stringify(wishlist)
    localStorage.setItem('wishlist', json)
  } catch (error) {
    return
  }
}

function removeLocalWishlist() {
  try {
    localStorage.removeItem('wishlist')
  } catch (error) {
    return
  }
}

function getWishlistFromEdges(nodes?: wishlist_wishlist_edges[]): Wishlist {
  const result = {}
  if (nodes) {
    nodes.forEach(({ node: { id } }) => {
      result[id] = true
    })
  }
  return result
}
