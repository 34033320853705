import { FC } from 'react'
import styles from './Captcha.module.scss'

const Captcha: FC = () => {
  return (
    <p className={styles.text}>
      Сайт использует reCAPTCHA согласно{' '}
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noreferrer"
      >
        Политике конфиденциальности
      </a>{' '}
      и{' '}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Условиям обслуживания
      </a>{' '}
      Google.
    </p>
  )
}

export default Captcha
