import gql from 'graphql-tag'

export const SEND_FORM = gql`
  mutation sendForm(
    $form: String!
    $name: String!
    $data: JSONObject!
    $token: String!
  ) {
    sendForm(form: $form, name: $name, data: $data, token: $token)
  }
`
