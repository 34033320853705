import { FC, useContext, useState } from 'react'
import cx from 'classnames'
import styles from './SocialAuth.module.scss'
import Loader from '../../common/Loader/Loader'
import { AuthContext } from '../../../contexts/auth'
import { useMutation } from '@apollo/client'
import { UNLINK_SOCNET } from '../../../queries/authQuery'
import {
  unlinkSocnet,
  unlinkSocnetVariables,
} from '../../../__generated__/unlinkSocnet'
import { Socnet } from '../../../__generated__/globalTypes'

interface Social {
  name: string
  endpoint: string
  linkEndpoint: string
}

const SOCIALS: Social[] = [
  {
    name: 'ВКонтакте',
    endpoint: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth/vkontakte`,
    linkEndpoint: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/link/vkontakte`,
  },
]

interface SocialAuth {
  title?: string
  link?: boolean
}

const SocialAuth: FC<SocialAuth> = ({ title, link }) => {
  const [loading, setLoading] = useState<string>()
  const { user, refetch } = useContext(AuthContext)

  const active = {
    ВКонтакте: user?.vk,
  }

  const [unlink] = useMutation<unlinkSocnet, unlinkSocnetVariables>(
    UNLINK_SOCNET,
  )

  const onDeactivate = async (name: string) => {
    const variables: unlinkSocnetVariables = {
      socnet: undefined,
    }

    if (name === 'ВКонтакте') {
      variables.socnet = Socnet.vk
    }

    await unlink({
      variables,
    })

    await refetch()

    setLoading(null)
  }

  return (
    <>
      {title && <p className={cx('text-big', styles.title)}>{title}</p>}
      <div className={styles.row}>
        {SOCIALS.map(({ name, endpoint, linkEndpoint }) => {
          const isActive = active[name]

          return (
            <a
              key={name}
              className={cx(
                styles.link,
                loading === name && styles.loading,
                isActive && styles.active,
              )}
              onClick={(e) => {
                setLoading(name)

                if (!link) {
                  return
                }

                if (isActive) {
                  e.preventDefault()
                  onDeactivate(name)
                }
              }}
              href={link ? linkEndpoint : endpoint}
            >
              {loading === name ? <Loader /> : name}
            </a>
          )
        })}
      </div>
    </>
  )
}

export default SocialAuth
