import React, { FC, useContext } from 'react'
import css from './Burger.module.scss'
import cx from 'classnames'
import { LayoutContext } from '../../../../contexts/layoutProvider'

const Burger: FC<{
  className?: string
  onClick?: (e: React.MouseEvent) => void
  onPointerEnter?: (e: React.MouseEvent) => void
  active: boolean
}> = ({ className, onClick, onPointerEnter, active }) => {
  const { headerColor, headerTextColor } = useContext(LayoutContext)
  return (
    <button
      type="button"
      className={cx(css.container, active && css.active, className)}
      onClick={onClick}
      onPointerEnter={onPointerEnter}
      title="Меню"
    >
      <span
        className={cx(
          css.wrapper,
          css[headerColor],
          css[`color-${headerTextColor}`],
        )}
      >
        <span className={css.line} />
        <span className={cx(css.line, css.long)} />
        <span className={css.line} />
      </span>
    </button>
  )
}

export default Burger
