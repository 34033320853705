import { FC, useContext } from 'react'
import { CartContext } from '../../../contexts/cart'
import { formatPrice } from '../../../lib/utils'
import styles from './FreeDelivery.module.scss'
import Mark from './mark.svg'
import cx from 'classnames'

const FreeDelivery: FC = () => {
  const { cart, inited } = useContext(CartContext)

  if (!inited) {
    return null
  }

  const total = cart?.info?.freeDeliveryFrom
  const current = cart?.info?.price
  const percent = (current / total) * 100
  const free = current >= total

  return (
    <div className={cx(styles.wrapper, free && styles.freeWrapper)}>
      {free ? (
        <p className={styles.free}>Вам доступна бесплатная доставка по РФ!</p>
      ) : (
        <>
          <div className={styles.bar}>
            <div
              className={styles.progress}
              style={{
                width: `${Math.min(Math.max(percent, 0), 100)}%`,
                // transform: `translate3d(${percent - 100}%,0,0)`,
              }}
            >
              <div className={styles.mark}>
                <Mark />
              </div>
            </div>
          </div>
          <p className={styles.bottom}>
            до бесплатной доставки осталось{' '}
            <span className={styles.price}>{formatPrice(total - current)}</span>
          </p>
        </>
      )}
    </div>
  )
}

export default FreeDelivery
