import { FC } from 'react'
import cx from 'classnames'
import styles from './Top.module.scss'

interface Top {
  title: string
  question?: string
  action?: string
  toggleForm?: () => void
}
const Top: FC<Top> = ({ title, question, action, toggleForm }) => {
  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>{title}</h4>
      {question && (
        <p className={cx('text-big', styles.text)}>
          {question}{' '}
          <button className={styles.action} type="button" onClick={toggleForm}>
            {action}
          </button>
        </p>
      )}
    </div>
  )
}

export default Top
