import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import css from './ToggleCheckBox.module.scss'

type ToggleCheckBox = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string | JSX.Element
  error?: FieldError
}

const ToggleCheckBox = forwardRef<HTMLInputElement, ToggleCheckBox>(
  ({ label, type = 'checkbox', ...props }, ref) => {
    return (
      <label className={css.wrapper}>
        <input className={css.checkbox} type={type} ref={ref} {...props} />
        <span className={css.toggle}>
          <span className={css.toggle_inner} />
        </span>
        <p className={css.title}>{label}</p>
      </label>
    )
  },
)

ToggleCheckBox.displayName = 'ToggleCheckBox'

export default ToggleCheckBox
