import React, { FC, useContext } from 'react'
import css from './SecondNav.module.scss'
import { LayoutContext } from '../../../../contexts/layoutProvider'
import MaxWidth from '../../MaxWidth/MaxWidth'
import { secondNav } from '../../../data'
import SecondNavItem from './SecondNavItem/SecondNavItem'
import cx from 'classnames'

const SecondNav: FC = () => {
  const { secondNavOpen } = useContext(LayoutContext)

  return (
    <nav className={cx(css.container, secondNavOpen && css.active)}>
      <MaxWidth>
        <div className={css.wrapper}>
          <ul className={css.navs}>
            {secondNav.map((item) => {
              return <SecondNavItem key={item.code} {...item} />
            })}
          </ul>
          <dl className={css.contacts}>
            <dt className={css.contactsTitle}>Телефон для связи</dt>
            <dd className={css.contactsValue}>
              <a href="tel:+79627012233" rel="noreferrer">
                +7 962 701 22 33
              </a>
            </dd>
            <dt className={css.contactsTitle}>E-mail</dt>
            <dd className={css.contactsValue}>
              <a href="mailto:info@kmcosmetics.ru" rel="noreferrer">
                info@kmcosmetics.ru
              </a>
            </dd>
          </dl>
        </div>
      </MaxWidth>
    </nav>
  )
}

export default SecondNav
