import { FC } from 'react'
import styles from './InputBtn.module.scss'
import cx from 'classnames'
import Arrow from './arrow.svg'
import Loader from '../common/Loader/Loader'

type InputBtn = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  loading?: boolean
  $disabled?: boolean
}

const InputBtn: FC<InputBtn> = ({
  children,
  className,
  $disabled,
  loading,
  ...props
}) => {
  return (
    <button
      className={cx(
        styles.button,
        $disabled && styles.disabled,
        loading && styles.loading,
        className,
      )}
      {...props}
    >
      {loading ? (
        <Loader />
      ) : (
        <span className={styles.children}>{children}</span>
      )}

      {loading || <Arrow />}
    </button>
  )
}

export default InputBtn
