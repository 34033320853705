import React, { FC, useState } from 'react'
import PlusIcon from '../../../public/static/icons/plus.svg'
import PlusBigIcon from '../../../public/static/icons/plus-big.svg'
import AnimateHeight from 'react-animate-height'
import css from './AccordionText.module.scss'
import cx from 'classnames'

const AccordionText: FC<{
  title: JSX.Element | string
  titleUppercase?: boolean
  letterSpacing?: boolean
  className?: string
  activeClassName?: string
  descriptionClassName?: string
  bigCross?: boolean
  right?: JSX.Element
}> = ({
  title,
  children,
  titleUppercase,
  className,
  letterSpacing,
  activeClassName,
  descriptionClassName,
  bigCross,
  right,
}) => {
  const [active, setActive] = useState(false)
  return (
    <div className={className}>
      <div
        className={cx(
          css.title,
          active && cx(css.active, activeClassName),
          titleUppercase && css.uppercase,
          letterSpacing && css.letters,
          bigCross && css.big,
        )}
        onClick={() => setActive((prev) => !prev)}
      >
        {typeof title === 'string' ? <span>{title}</span> : title}
        {!active && right ? (
          right
        ) : bigCross ? (
          <PlusBigIcon viewBox="0 0 23 23" />
        ) : (
          <PlusIcon viewBox="0 0 14 13" />
        )}
      </div>

      <AnimateHeight height={active ? 'auto' : 0}>
        <div className={cx(css.description, descriptionClassName)}>
          {children}
        </div>
      </AnimateHeight>
    </div>
  )
}

export default AccordionText
