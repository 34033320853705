import React, { createContext, FC, useEffect, useState } from 'react'
import useWindowSize from '../hooks/useWindowSize'
import {
  HeaderColors,
  HeaderTextColors,
  IJulireaNavItem,
  IMoreBlock,
  INavCategory,
  INavItem,
} from '../components/types'
import { useQuery } from '@apollo/client'
import { SECTIONS } from '../queries/catalogQuery'
import { getAllSectionsVariables } from '../lib/gql'
import { sections, sectionsVariables } from '../__generated__/sections'
import { CATALOG_HREF } from '../components/data'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { julirea_nav, julirea_navVariables } from '../__generated__/julirea_nav'
import { JULIREA_NAV } from '../queries/julireaQueries'
import { moreQuery, moreQueryVariables } from '../__generated__/moreQuery'
import { MORE_QUERY } from '../queries/moreQuery'
import { createMoreBlock } from '../lib/createMoreBlock'

interface ILayoutContext {
  isMobile: boolean
  searchOpen: boolean
  setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
  secondNavOpen: boolean
  setSecondNavOpen: React.Dispatch<React.SetStateAction<boolean>>
  mobileNavOpen: boolean
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>
  innerNav: INavItem[]
  setInnerNav: React.Dispatch<React.SetStateAction<INavItem[]>>
  mainNav: INavCategory[]
  setScrolledElement: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLElement>>
  >
  headerColor: HeaderColors
  headerInitColor: HeaderColors
  setHeaderColor: React.Dispatch<React.SetStateAction<HeaderColors>>
  showInnerNav: boolean
  hideBorder: boolean
  headerTextColor: HeaderTextColors
  setHeaderTextColor: React.Dispatch<React.SetStateAction<HeaderTextColors>>
  fixed: boolean
  setFixed: React.Dispatch<React.SetStateAction<boolean>>
  commonErrorMessage: string
  setCommonErrorMessage: React.Dispatch<React.SetStateAction<string>>
  julireaNav: IJulireaNavItem[]
  more: IMoreBlock
}

export const LayoutContext = createContext({} as ILayoutContext)

const LayoutProvider: FC<{
  headerInitColor: HeaderColors
  showInnerNav: boolean
  hideBorder?: boolean
}> = ({ children, headerInitColor, showInnerNav, hideBorder }) => {
  const { isMobile } = useWindowSize()
  const [searchOpen, setSearchOpen] = useState(false)
  const [secondNavOpen, setSecondNavOpen] = useState(false)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [innerNav, setInnerNav] = useState<INavItem[]>()
  const [mainNav, setMainNav] = useState<INavCategory[]>()
  const [scrolledElement, setScrolledElement] =
    useState<React.RefObject<HTMLElement>>(null)
  const [headerColor, setHeaderColor] = useState(
    headerInitColor ? headerInitColor : HeaderColors.white,
  )
  const [headerTextColor, setHeaderTextColor] = useState(HeaderTextColors.init)
  const [commonErrorMessage, setCommonErrorMessage] = useState('')
  const [fixed, setFixed] = useState(false)

  //socnets and more info
  const { data } = useQuery<moreQuery, moreQueryVariables>(MORE_QUERY, {
    variables: {
      where: {
        iblock: { code: { equals: 'more-things-in-socnets' } },
        code: {
          equals:
            'bolshe-primerov-i-videourokov-mozhno-nayti-v-nashikh-sotssetyakh',
        },
        active: { equals: true },
      },
    },
  })

  const more = createMoreBlock(data)

  useEffect(() => {
    setHeaderColor(headerInitColor)
  }, [headerInitColor])

  useEffect(() => {
    if (!window && !document) {
      return
    }

    const storedScrollY = window.scrollY

    if (mobileNavOpen) {
      disableBodyScroll(scrolledElement)
      document.body.style.setProperty('top', `${window.scrollY * -1}px`)
    } else {
      clearAllBodyScrollLocks(scrolledElement)
      document.body.style.setProperty('top', '')
      document.body.scrollTo(0, storedScrollY)
    }
  }, [mobileNavOpen])

  useEffect(() => {
    isMobile ? setSecondNavOpen(false) : setMobileNavOpen(false)
  }, [isMobile])

  // get main nav
  const { loading, error } = useQuery<sections, sectionsVariables>(SECTIONS, {
    variables: { ...getAllSectionsVariables(), first: 999 },
    onCompleted: (data) => {
      const nav: INavCategory[] = []

      if (!loading && !error) {
        const sections = data?.sections?.nodes?.filter(
          (section) => section.level === 1,
        )

        sections?.map((section) => {
          const navItem: INavCategory = {
            title: section.name,
            href: CATALOG_HREF,
            as: `/catalog/${section.code}`,
            code: section.code,
            items: [],
          }

          data?.sections?.nodes
            ?.filter(
              (item) =>
                item.level === 2 && item.parentSection.code === section.code,
            )
            .map((subSection) => {
              navItem.items.push({
                title: subSection.name,
                href: CATALOG_HREF,
                as: `/catalog/${section.code}/${subSection.code}`,
                code: `${section.code}-${subSection.code}`,
              })
            })

          nav.push(navItem)
        })

        setMainNav(nav)
      }
    },
  })

  const { data: julireaNavData } = useQuery<julirea_nav, julirea_navVariables>(
    JULIREA_NAV,
    {
      variables: {
        where: {
          active: { equals: true },
          iblock: {
            code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE },
          },
          parentSection: { code: { equals: 'julirea' } },
          level: { equals: 2 },
        },
        first: 999,
      },
    },
  )

  const julireaNav: IJulireaNavItem[] = julireaNavData?.sections?.nodes
    ?.filter((node) => node?.elements?.length > 0)
    ?.map((node) => ({ id: node.id, code: node.code, title: node.name }))

  return (
    <LayoutContext.Provider
      value={{
        isMobile,
        searchOpen,
        setSearchOpen,
        secondNavOpen,
        setSecondNavOpen,
        mobileNavOpen,
        hideBorder,
        setMobileNavOpen,
        innerNav,
        setInnerNav,
        mainNav,
        setScrolledElement,
        headerColor,
        headerInitColor,
        setHeaderColor,
        showInnerNav,
        headerTextColor,
        setHeaderTextColor,
        fixed,
        setFixed,
        commonErrorMessage,
        setCommonErrorMessage,
        julireaNav,
        more,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutProvider
