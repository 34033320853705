import { FC, useContext, useEffect, useRef, useState } from 'react'
import Captcha from './Captcha'
import Login from './Login'
import Register from './Register'
import styles from './Auth.module.scss'
import Recovery from './Recovery'
import { useRouter } from 'next/router'
import Change from './Change'
import { LayoutContext } from '../../../contexts/layoutProvider'

export enum AuthVariant {
  signin = 'signin',
  signup = 'signup',
  recovery = 'recovery',
  change = 'change',
}

interface Auth {
  closeModal: () => void
}
const Auth: FC<Auth> = ({ closeModal }) => {
  const { query } = useRouter()
  const { commonErrorMessage, setCommonErrorMessage } =
    useContext(LayoutContext)
  const timer = useRef<NodeJS.Timeout>()
  const [authVariant, setAuthVariant] = useState<AuthVariant>(
    query.change_password ? AuthVariant.change : AuthVariant.signin,
  )
  const [success, setSuccess] = useState<string>()

  useEffect(() => {
    if (!success && !commonErrorMessage) {
      return
    }

    timer.current = setTimeout(() => {
      setSuccess(undefined)
      setCommonErrorMessage('')
    }, 5000)
  }, [success, commonErrorMessage])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  let form: React.ReactElement

  switch (authVariant) {
    case AuthVariant.signin:
      form = (
        <Login
          setAuthVariant={setAuthVariant}
          closeModal={closeModal}
          setError={setCommonErrorMessage}
        />
      )
      break

    case AuthVariant.signup:
      form = (
        <Register
          setAuthVariant={setAuthVariant}
          closeModal={closeModal}
          setError={setCommonErrorMessage}
        />
      )
      break

    case AuthVariant.recovery:
      form = (
        <Recovery
          setAuthVariant={setAuthVariant}
          setSuccess={setSuccess}
          setError={setCommonErrorMessage}
        />
      )
      break

    case AuthVariant.change:
      form = (
        <Change
          setAuthVariant={setAuthVariant}
          setSuccess={setSuccess}
          setError={setCommonErrorMessage}
        />
      )
      break

    default:
      break
  }

  if (success) {
    form = <p>{success}</p>
  }

  return (
    <>
      <div className={styles.form}>{form}</div>
      <Captcha />
    </>
  )
}

export default Auth
