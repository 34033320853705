import React, { FC } from 'react'
import Link from 'next/link'
import css from './Logo.module.scss'

import LogoIcon from '../../../../public/static/icons/logo.svg'
import LogoSmallIcon from '../../../../public/static/icons/logo-small.svg'

const Logo: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <Link href="/" passHref>
      <a onClick={onClick} title="Главная страница" className={css.link}>
        <LogoSmallIcon className={css.logo_mobile} />
        <LogoIcon className={css.logo} />
      </a>
    </Link>
  )
}

export default Logo
