import React, { FC } from 'react'
import css from './FooterNavItem.module.scss'
import Link from 'next/link'
import { INavCategory } from '../../../../types'
import { CATALOG_HREF } from '../../../../data'
import cx from 'classnames'

const FooterNavItem: FC<INavCategory> = ({
  code,
  title,
  items,
}: INavCategory) => {
  return (
    <li
      className={css.container}
      role="navigation"
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      <dl>
        <dt className={css.title}>{title}</dt>
        <dd>
          <ul className={css.list}>
            {items?.map((item, index) => {
              const href = code === 'catalog' ? CATALOG_HREF : item.href
              const as = code === 'catalog' ? item.as : null

              return (
                <li className={css.item} key={index}>
                  <Link href={href} as={as} passHref>
                    <a
                      className={cx(
                        css.link,
                        item.code === 'detskaya_mineralnaya_kosmetika' &&
                          css.gradient,
                      )}
                      itemProp="url"
                      title={item.title}
                    >
                      <span itemProp="name">{item.title}</span>
                    </a>
                  </Link>
                </li>
              )
            })}
          </ul>
        </dd>
      </dl>
    </li>
  )
}

export default FooterNavItem
