import gql from 'graphql-tag'
import { ELEMENT_SEO_FIELDS, SECTION_SEO_FIELDS } from './seoQuery'

export const PAGE_INFO = gql`
  fragment pageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`

export const SECTION_FIELDS = gql`
  fragment sectionFields on Section {
    id
    code
    name
    level
    parentSection {
      id
      code
      name
      level
    }
  }
`

export const ELEMENT_BASE_FIELDS = gql`
  fragment elementBaseFields on Element {
    id
    bx_id
    code
    name
    detailText
    previewText
    active
    priceBase
    priceBaseWithDiscount
    sort
    previewPicture {
      id
      url
      name
    }
    detailPicture {
      id
      url
      name
    }
    sections {
      id
      code
      name
      parentSection {
        id
        code
        name
      }
      propertyValues {
        property {
          code
          id
        }
        id
        value
      }
    }
    propertyValues {
      property {
        code
        id
      }
      id
      value
      values
      description
      descriptions
    }
    propertyValuesEnum {
      property {
        id
        code
      }
      id
      value
    }
  }
`

export const ELEMENT_FIELDS = gql`
  fragment elementFields on Element {
    ...elementBaseFields
    propertyValuesRelation {
      id
      property {
        id
        code
      }
      targetHlElement {
        id
        name
        xml_id
      }
      targetElement {
        ...elementBaseFields
      }
      targetElements {
        id
        priceBase
        priceBaseWithDiscount
      }
      targetFile {
        id
        url
      }
      targetFiles {
        id
        url
      }
    }
  }
  ${ELEMENT_BASE_FIELDS}
`

export const PRODUCT_FIELDS = gql`
  fragment productFields on Element {
    id
    priceBase
    priceBaseWithDiscount
    bx_id
    sku(
      where: {
        active: { equals: true }
        OR: [
          { activeFrom: { lte: $today }, activeTo: { equals: null } }
          { activeFrom: { lte: $today }, activeTo: { gte: $today } }
          { activeFrom: { equals: null }, activeTo: { equals: null } }
          { activeFrom: { equals: null }, activeTo: { gte: $today } }
        ]
      }
      orderBy: { sort: asc }
    ) {
      id
      name
      bx_id
      priceBase
      priceBaseWithDiscount
      detailPicture {
        id
        url
      }
      propertyValues {
        id
        value
        property {
          id
          code
        }
      }
      propertyValuesRelation {
        id
        property {
          id
          name
          code
        }
        targetFiles {
          id
          url
        }
        targetHlElement {
          id
          name
          file {
            url
          }
        }
        targetElement {
          id
          name
          priceBase
          bx_id
        }
      }
    }
  }
`

export const ELEMENT_EDGES = gql`
  fragment elementEdges on ElementEdge {
    cursor
    node {
      ...elementFields
      sku(where: { active: { equals: true } }) {
        id
        name
        priceBase
        priceBaseWithDiscount
      }
    }
  }
  ${ELEMENT_FIELDS}
`

export const SECTION = gql`
  query section($where: SectionWhereInput) {
    sections(first: 1, where: $where, order: { code: asc }) {
      nodes {
        ...sectionFields
        ...sectionSeoFields
      }
    }
  }
  ${SECTION_FIELDS}
  ${SECTION_SEO_FIELDS}
`

export const SECTIONS = gql`
  query sections(
    $first: Int
    $where: SectionWhereInput
    $orderBy: SectionOrderByInput!
  ) {
    sections(first: $first, where: $where, order: $orderBy) {
      nodes {
        ...sectionFields
      }
    }
  }
  ${SECTION_FIELDS}
`

export const ELEMENTS = gql`
  query elements(
    $first: Int
    $after: String
    $order: ElementOrderByInput
    $where: ElementWhereInput
  ) {
    elements(first: $first, after: $after, order: $order, where: $where)
      @connection(key: "elements", filter: ["order", "where"]) {
      count
      pageInfo {
        ...pageInfo
      }
      edges {
        ...elementEdges
      }
    }
  }
  ${ELEMENT_EDGES}
  ${PAGE_INFO}
`

export const ELEMENT = gql`
  query element($where: ElementWhereInput!) {
    element(where: $where) {
      ...elementFields
    }
  }
  ${ELEMENT_FIELDS}
`
export const PRODUCT = gql`
  query product(
    $where: ElementWhereInput!
    $instagramWhere: ElementWhereInput
    $instagramFirst: Int
    $today: DateTime
  ) {
    product: element(where: $where) {
      ...elementFields
      ...productFields
      ...elementSeoFields

      propertyValuesRelation {
        targetElements {
          ...elementFields
          ...productFields
        }

        targetFiles {
          id
          url
        }

        targetElement {
          id
          name
          code
          detailText
          detailPicture {
            id
            url
          }
          propertyValuesRelation {
            id
            property {
              id
              code
            }

            targetFile {
              id
              url
            }
          }
        }
      }
    }

    instagram: elements(
      where: $instagramWhere
      first: $instagramFirst
      order: { createdAt: desc }
    ) {
      nodes {
        id
        detailPicture {
          id
          url
        }

        propertyValues {
          property {
            id
            code
          }
          id
          value
        }
      }
    }
  }
  ${ELEMENT_FIELDS}
  ${ELEMENT_SEO_FIELDS}
  ${PRODUCT_FIELDS}
`

export const PRODUCT_DISCOUNT = gql`
  query productDiscount($productId: String!) {
    productDiscount(productId: $productId) {
      id
      name
      active
      activeFrom
      activeTo
      kind
      value
    }
  }
`
