import { FC, useContext } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { SubmitHandler, useForm } from 'react-hook-form'
import ym from 'react-yandex-metrika'
import { AuthContext } from '../../../contexts/auth'
import { isNotEmptyInput, requiredMessage } from '../../../lib/form'
import FieldWrapper from '../../fields/FieldWrapper/FieldWrapper'
import Input from '../../fields/Input/Input'
import InputBtn from '../../fields/InputBtn'
import ToggleCheckBox from '../../fields/ToggleCheckBox/ToggleCheckBox'
import { AuthVariant } from './Auth'
import styles from './Register.module.scss'
import SocialAuth from './SocialAuth'
import Top from './Top'

interface Register {
  setAuthVariant: React.Dispatch<React.SetStateAction<AuthVariant>>
  setError: React.Dispatch<React.SetStateAction<string>>
  closeModal: () => void
}

type FormValues = {
  email: string
  password: string
  passwordConfirm: string
  newsletter: boolean
  privacyPolicy: boolean
}

const Register: FC<Register> = ({ setAuthVariant, closeModal, setError }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const { signup, signupLoading } = useContext(AuthContext)

  const password = watch('password')

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (signupLoading) {
      return
    }

    const token = await executeRecaptcha()

    const result = await signup({
      email: data.email,
      password: data.password,
      password2: data.passwordConfirm,
      subscribe: data.newsletter,
      token,
    })

    if (result.success) {
      closeModal()
      ym('reachGoal', 'km-register-success')
    } else {
      setError(result.message)
    }
  }

  return (
    <form
      className={styles.form}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      method="POST"
    >
      <Top
        title="Регистрация"
        question="Есть аккаунт?"
        action="Авторизуйтесь"
        toggleForm={() => setAuthVariant(AuthVariant.signin)}
      />

      <FieldWrapper>
        <SocialAuth title="Зарегистрироваться через" />
      </FieldWrapper>

      <FieldWrapper
        label="Электронная почта *"
        errorMessage={errors?.email?.message}
      >
        <Input
          ref={register({
            required: requiredMessage,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Некорректная почта',
            },
            validate: (value) => isNotEmptyInput(value) || requiredMessage,
          })}
          required
          name="email"
          id="email"
          error={errors?.email}
        />
      </FieldWrapper>

      <FieldWrapper label="Пароль *" errorMessage={errors?.password?.message}>
        <Input
          ref={register({
            required: requiredMessage,
            validate: (value) => {
              if (!isNotEmptyInput(value)) {
                return requiredMessage
              }

              if (value.length < 6) {
                return 'Пароль должен быть длиннее 6 символов'
              }
            },
          })}
          required
          type="password"
          name="password"
          id="password"
          error={errors?.password}
        />
      </FieldWrapper>

      <FieldWrapper
        label="Повторите Пароль *"
        errorMessage={errors?.passwordConfirm?.message}
      >
        <Input
          ref={register({
            required: requiredMessage,
            validate: (value) => {
              if (!isNotEmptyInput(value)) {
                return requiredMessage
              }

              if (value.length < 6) {
                return 'Пароль должен быть длиннее 6 символов'
              }

              if (password !== value) {
                return 'Пароли не совпадают'
              }
            },
          })}
          required
          type="password"
          name="passwordConfirm"
          id="passwordConfirm"
          error={errors?.passwordConfirm}
        >
          <InputBtn $disabled={!isValid} loading={signupLoading} type="submit">
            Войти
          </InputBtn>
        </Input>
      </FieldWrapper>

      <FieldWrapper>
        <ToggleCheckBox
          defaultChecked
          name="newsletter"
          id="newsletter"
          label="согласен получать информационные письма о скидках, акциях и новинках бренда"
          ref={register()}
        />
      </FieldWrapper>

      <FieldWrapper errorMessage={errors?.privacyPolicy?.message}>
        <ToggleCheckBox
          defaultChecked
          name="privacyPolicy"
          id="privacyPolicy"
          label="согласен с условиями политики в отношении обработки персональных данных."
          ref={register({
            validate: (value) => {
              if (!value) {
                return requiredMessage
              }
            },
          })}
        />
      </FieldWrapper>
    </form>
  )
}

export default Register
