import gql from 'graphql-tag'

const USER_FRAGMENT = gql`
  fragment user on User {
    id
    name
    surname
    patronymic
    city
    phone
    email
    vk
    fb
    birthday
    groups {
      id
      bx_id
      code
      name
    }
  }
`

export const ME = gql`
  query me {
    me {
      success
      message
      user {
        ...user
      }
    }
  }
  ${USER_FRAGMENT}
`

export const SIGNUP = gql`
  mutation signup(
    $email: String!
    $password: String!
    $password2: String!
    $subscribe: Boolean
    $token: String
  ) {
    signup(
      email: $email
      password: $password
      password2: $password2
      subscribe: $subscribe
      token: $token
    ) {
      success
      message
      user {
        ...user
      }
    }
  }
  ${USER_FRAGMENT}
`

export const SIGNIN = gql`
  mutation signin($email: String!, $password: String!, $token: String) {
    signin(email: $email, password: $password, token: $token) {
      success
      message
      user {
        ...user
      }
    }
  }
  ${USER_FRAGMENT}
`

export const LOGOUT = gql`
  mutation logout($token: String) {
    logout(token: $token) {
      success
      message
    }
  }
`

export const RECOVERY = gql`
  mutation recovery($email: String!, $token: String) {
    recoveryPassword(email: $email, token: $token) {
      success
      message
    }
  }
`

export const CHANGE = gql`
  mutation change(
    $login: String!
    $checkword: String!
    $password: String!
    $password2: String!
    $token: String
  ) {
    changePassword(
      login: $login
      checkword: $checkword
      password: $password
      password2: $password2
      token: $token
    ) {
      success
      message
    }
  }
`

export const UPDATE_INFO = gql`
  mutation updateInfo(
    $name: String!
    $surname: String!
    $patronymic: String!
    $token: String
  ) {
    userUpdatePersonalInfo(
      name: $name
      surname: $surname
      patronymic: $patronymic
      token: $token
    ) {
      success
      message
      user {
        ...user
      }
    }
  }
  ${USER_FRAGMENT}
`

export const UPDATE_CONTACT = gql`
  mutation updateContact(
    $city: String!
    $country: String!
    $phone: String!
    $email: String!
    $token: String
  ) {
    userUpdateContactInfo(
      city: $city
      country: $country
      phone: $phone
      email: $email
      token: $token
    ) {
      success
      message
      user {
        ...user
      }
    }
  }

  ${USER_FRAGMENT}
`

export const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $password: String!
    $password2: String!
    $token: String
  ) {
    userUpdatePassword(
      password: $password
      password2: $password2
      token: $token
    ) {
      success
      message
    }
  }
`

export const UNLINK_SOCNET = gql`
  mutation unlinkSocnet($socnet: Socnet!) {
    unlinkSocnet(socnet: $socnet)
  }
`

export const UPDATE_BIRTHDAY = gql`
  mutation userUpdatePersonalBirthday($birthday: DateTime!, $token: String) {
    userUpdatePersonalBirthday(birthday: $birthday, token: $token) {
      success
      message
      user {
        ...user
      }
    }
  }
  ${USER_FRAGMENT}
`
