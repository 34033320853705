import React, { FC } from 'react'
import css from './InnerNav.module.scss'
import Link from 'next/link'
import { INavItem } from '../../../types'
import MaxWidth from '../../MaxWidth/MaxWidth'
import cx from 'classnames'

const InnerNav: FC<{ items: INavItem[] }> = ({ items }) => {
  return (
    <nav
      className={css.container}
      role="navigation"
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      <MaxWidth className={css.wrapper} wide={true}>
        <ul className={css.list}>
          {items?.map((item) => {
            if (!item.href) {
              return (
                <li className={css.item} key={`inner_nav_${item.code}`}>
                  <a
                    onClick={item.onClick}
                    className={cx(css.link, item.selected && css.selected)}
                    itemProp="url"
                    title={item.title}
                  >
                    <span itemProp="name">{item.title}</span>
                  </a>
                </li>
              )
            }

            return (
              <li className={css.item} key={`inner_nav_${item.code}`}>
                <Link href={item.href} as={item.as} passHref>
                  <a
                    className={cx(css.link, item.selected && css.selected)}
                    itemProp="url"
                    title={item.title}
                  >
                    <span itemProp="name">{item.title}</span>
                  </a>
                </Link>
              </li>
            )
          })}
        </ul>
      </MaxWidth>
    </nav>
  )
}

export default InnerNav
