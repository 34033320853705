import { FC, useContext } from 'react'
import styles from './CartAsideTop.module.scss'
import ArrowIcon from '../../../public/static/icons/arrow-left.svg'
import { CartContext } from '../../../contexts/cart'

interface CartAsideTop {
  onClose: () => void
}
const CartAsideTop: FC<CartAsideTop> = ({ onClose }) => {
  const { cart } = useContext(CartContext)
  const total = cart?.info?.quantity || 0

  return (
    <div className={styles.row}>
      <button className={styles.back} type="button" onClick={onClose}>
        <ArrowIcon />
      </button>
      <p className={styles.text}>
        в корзине
        <span className={styles.count}>{total}</span>
      </p>
      <span />
    </div>
  )
}

export default CartAsideTop
