import React, { FC } from 'react'
import NextImage, { ImageLoader, ImageProps } from 'next/image'
import { getImageSrc } from '../../../lib/utils'

const lerp = (x: any, y: any, a: any) => x * (1 - a) + y * a
const clamp = (a: any, min = 0, max = 1) => Math.min(max, Math.max(min, a))
const invlerp = (x: any, y: any, a: any) => clamp((a - x) / (y - x))
const range = (x1: any, y1: any, x2: any, y2: any, a: any) =>
  lerp(x2, y2, invlerp(x1, y1, a))

const loader: ImageLoader = ({ src, width, quality }) =>
  `${process.env.NEXT_PUBLIC_IMAGE_OPTIMIZER}/rs:fill${
    width ? `/w:${width}` : ''
  }/q:${
    quality ? quality : Math.round(range(2000, 500, 75, 85, width))
  }/plain/${getImageSrc(src)}`

interface Image extends ImageProps {
  local?: boolean
}

const Image: FC<Image> = ({ local, ...props }) => {
  return (
    <NextImage
      {...props}
      lazyBoundary="40% 0px"
      loader={local ? undefined : loader}
    />
  )
}

export default Image
