import { elementEdges } from '../__generated__/elementEdges'
import { elementFields } from '../__generated__/elementFields'
import { sectionVariables } from '../__generated__/section'
import { sectionsVariables } from '../__generated__/sections'
import { ElementOrderByInput, SortOrder } from '../__generated__/globalTypes'
import { elementsVariables } from '../__generated__/elements'
import { aboutVariables } from '../__generated__/about'
import { info_pageVariables } from '../__generated__/info_page'
import { main_pageVariables } from '../__generated__/main_page'
import { journalYoutubeItemsVariables } from '../__generated__/journalYoutubeItems'
import { journalSectionsVariables } from '../__generated__/journalSections'
import { productVariables } from '../__generated__/product'

type Value = {
  property: { code: string; id?: string } | null
  value?: string
  values?: string[]
  descriptions?: string[]
  targetFile?: { url: string } | null
}

type ValueWithElement = Value & {
  targetElement?: elementFields
}
type ValueWithFiles = Value & {
  targetFiles?: { url: string }[] | null
}

type GetProp = (values: Value[], code: string) => string | undefined
type GetPropArr = (values: Value[], code: string) => string[] | undefined

type GetPropTargetElement = (
  values: ValueWithElement[],
  code: string,
) => elementFields | null | undefined
type GetPropTargetFiles = (
  values: ValueWithFiles[],
  code: string,
) => { url: string; id?: string }[] | null | undefined

export const getPropByCode: GetProp = (values, code) => {
  return values?.find((prop) => prop?.property?.code === code)?.value
}
export const getPropIdByCode: GetProp = (values, code) => {
  return values?.find((prop) => prop?.property?.code === code)?.property?.id
}
export const getPropValuesByCode: GetPropArr = (values, code) => {
  return values?.find((prop) => prop?.property?.code === code)?.values
}
export const getPropDescriptionsByCode: GetPropArr = (values, code) => {
  return values?.find((prop) => prop?.property?.code === code)?.descriptions
}

export const getPropFileByCode: GetProp = (values, code) => {
  return values?.find((prop) => prop?.property?.code === code)?.targetFile?.url
}
export const getPropFilesByCode: GetPropTargetFiles = (values, code) => {
  return values?.find((prop) => prop?.property?.code === code)?.targetFiles
}

export const getPropElementByCode: GetPropTargetElement = (values, code) => {
  return values?.find((prop) => prop.property?.code === code)?.targetElement
}

export const getEdgesNodes = (edges: elementEdges[]): elementFields[] => {
  const nodes: elementFields[] = []
  edges?.map((edge) => {
    if (!edge.node) {
      return
    }
    nodes.push(edge.node)
  }, [])

  return nodes
}

export const getElementVariables = ({
  elementCode,
  sectionCode,
  today,
}: {
  elementCode: string
  sectionCode: string
  today: string
}): productVariables => {
  return {
    where: {
      active: { equals: true },
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      code: { equals: elementCode },
      sections: {
        some: {
          code: {
            equals: sectionCode,
          },
        },
      },
      OR: [
        {
          activeFrom: { lte: today },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: today },
          activeTo: { gte: today },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: today },
        },
      ],
    },
    instagramWhere: {
      iblock: { code: { equals: 'instagram' } },
      active: { equals: true },
      propertyValuesRelation: {
        some: {
          targetElements: { some: { code: { equals: elementCode } } },
        },
      },
    },

    instagramFirst: 6,
    today,
  }
}

export const getSubSectionElementsVariables = ({
  first,
  after = '',
  sectionCode,
  order,
  now,
}: {
  first: number
  after?: string
  sectionCode: string
  order: ElementOrderByInput
  now: string
}): elementsVariables => {
  return {
    first,
    after,
    order,
    where: {
      active: { equals: true },
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      sections: {
        some: {
          code: {
            equals: sectionCode,
          },
        },
      },
      OR: [
        {
          activeFrom: { lte: now },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: now },
          activeTo: { gte: now },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: now },
        },
      ],
    },
  }
}

export const getParentSectionElementsVariables = ({
  first,
  after = '',
  sectionCode,
  order,
  now,
}: {
  first: number
  after?: string
  sectionCode: string
  order: ElementOrderByInput
  now: string
}): elementsVariables => {
  return {
    first,
    after,
    order,
    where: {
      active: { equals: true },
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      sections: {
        some: {
          OR: [
            { code: { equals: sectionCode } },
            { parentSection: { code: { equals: sectionCode } } },
          ],
        },
      },
      OR: [
        {
          activeFrom: { lte: now },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: now },
          activeTo: { gte: now },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: now },
        },
      ],
    },
  }
}

export const getSectionVariables = ({
  sectionCode,
}: {
  sectionCode: string
}): sectionVariables => {
  return {
    where: {
      active: { equals: true },
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      code: { equals: sectionCode },
    },
  }
}

export const getAllSectionsVariables = (): sectionsVariables => {
  return {
    orderBy: { sort: SortOrder.asc },
    where: {
      active: { equals: true },
      iblock: {
        code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE },
      },
      level: { lte: 2 },
      code: { not: { equals: 'julirea' } },
    },
    first: 10,
  }
}

export const getSubSectionsVariables = ({
  sectionCode,
}: {
  sectionCode: string
}): sectionsVariables => {
  return {
    orderBy: { sort: SortOrder.asc },
    where: {
      active: { equals: true },
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      parentSection: { code: { equals: sectionCode } },
      level: { equals: 2 },
    },
    first: 10,
  }
}

export const getSectionsVariables = (): sectionsVariables => {
  return {
    orderBy: { code: SortOrder.asc },
    where: {
      active: { equals: true },
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      level: { equals: 1 },
    },
    first: 10,
  }
}

export const getCatalogElementUrl = (
  element: elementFields,
  isJulireaPage?: boolean,
): string | null | undefined => {
  if (!element || !element.sections) {
    return null
  }
  const { code, sections } = element

  const link = sections
    .filter((section) => section?.parentSection?.code)
    .map((section) => {
      if (section?.parentSection?.code === 'julirea' && isJulireaPage) {
        return `/julirea/${code}`
      } else if (section?.parentSection?.code === 'julirea' && !isJulireaPage) {
        const parentSection = sections.filter(
          (section) => section?.parentSection?.code !== 'julirea',
        )[0]?.parentSection?.code

        return `/catalog/${parentSection}/${section.code}/${code}`
      } else if (section.parentSection) {
        return `/catalog/${section.parentSection.code}/${section.code}/${code}`
      }
    })

  return link[0] ? link[0] : `/catalog/${sections[0].code}/${code}`
}

export const getCatalogRootVariables = ({
  first,
  after = '',
  order,
  today,
}: {
  first: number
  after?: string
  order: ElementOrderByInput
  today: string
}): elementsVariables => {
  return {
    where: {
      active: { equals: true },
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      sections: {
        some: {
          active: { equals: true },
          code: { not: { equals: 'julirea' } },
          parentSection: { code: { not: { equals: 'julirea' } } },
        },
      },

      OR: [
        {
          activeFrom: { lte: today },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: today },
          activeTo: { gte: today },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: today },
        },
      ],
    },
    first,
    after,
    order,
  }
}

export const getAboutPageVariables = (code: string): aboutVariables => {
  return {
    where: {
      iblock: {
        code: {
          equals: process.env.NEXT_PUBLIC_ABOUT_IBLOCK_CODE,
        },
      },
      active: { equals: true },
      code: {
        equals: code,
      },
    },
    videoWhere: {
      code: `UF_${code.toUpperCase()}_VIDEO`,
    },
    partnersFirst: 999,
    partnersWhere: {
      active: { equals: true },
      iblock: { code: { equals: 'partners' } },
      sections: { some: { code: { equals: 'about-partners' } } },
    },
  }
}
export const getInfoPageVariables = (code: string): info_pageVariables => {
  return {
    where: {
      iblock: {
        code: {
          equals: process.env.NEXT_PUBLIC_INFO_PAGE_IBLOCK_CODE,
        },
      },
      active: { equals: true },
      code: {
        equals: code,
      },
    },
  }
}

export const getMainPageVariables = (today: string): main_pageVariables => {
  return {
    sliderWhere: {
      iblock: {
        code: {
          equals: 'slider-main',
        },
      },
      active: {
        equals: true,
      },
      OR: [
        {
          activeFrom: { lte: today },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: today },
          activeTo: { gte: today },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: today },
        },
      ],
    },
    textElementsWhere: {
      iblock: { code: { equals: 'main-page' } },
      active: { equals: true },
    },
    videoWhere: {
      code: 'UF_MAIN_PAGE_VIDEO',
    },
    BAWhere: {
      OR: [
        { code: { equals: 'UF_MAIN_BEFORE' } },
        { code: { equals: 'UF_MAIN_AFTER' } },
        { code: { equals: 'UF_MAIN_PAGE_BEFORE_AFTER_SECTION' } },
      ],
    },
    categoryWhere: {
      code: 'UF_MAIN_PAGE_CATEGORY',
    },
    categoryElementsWhere: {
      active: { equals: true },
      OR: [
        {
          activeFrom: { lte: today },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: today },
          activeTo: { gte: today },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: today },
        },
      ],
    },
    journalWhere: {
      iblock: { code: { equals: 'articles' } },
      active: { equals: true },
      OR: [
        {
          activeFrom: { lte: today },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: today },
          activeTo: { gte: today },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: today },
        },
      ],
    },
    benefitsWhere: {
      iblock: { code: { equals: process.env.NEXT_PUBLIC_CATALOG_IBLOCK_CODE } },
      active: { equals: true },
      propertyValuesEnum: {
        some: {
          property: { code: { equals: 'SPECIALOFFER' } },
          value: { equals: 'да' },
        },
      },
      OR: [
        {
          activeFrom: { lte: today },
          activeTo: { equals: null },
        },
        {
          activeFrom: { lte: today },
          activeTo: { gte: today },
        },
        {
          activeFrom: { equals: null },
          activeTo: { equals: null },
        },
        {
          activeFrom: { equals: null },
          activeTo: { gte: today },
        },
      ],
    },
    videoSliderWhere: {
      iblock: {
        code: {
          equals: 'slider-main-video',
        },
      },
      active: {
        equals: true,
      },
    },
    videoSliderOrder: { sort: SortOrder.asc },
    videoSliderFirst: 10,
    mainSliderFirst: 999,
    categoryFirst: 4,
    first: 3,
  }
}

export const getJournalYoutubeVariables = (): journalYoutubeItemsVariables => {
  return {
    where: {
      iblock: { code: { equals: 'articles-videos' } },
      active: { equals: true },
    },
    first: 3,
    order: { activeFrom: SortOrder.desc },
  }
}

export const getJournalSectionsVariables = (): journalSectionsVariables => {
  return {
    where: {
      iblock: { code: { equals: 'articles' } },
      active: { equals: true },
    },
    first: 999,
    order: { sort: SortOrder.asc },
  }
}
