import gql from 'graphql-tag'
import { ELEMENT_EDGES, ELEMENT_FIELDS } from './catalogQuery'

export const WISHLIST = gql`
  query wishlist($first: Int!, $after: String) {
    wishlist(first: $first, after: $after) {
      count
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`

export const WISHLIST_ITEMS = gql`
  query wishlistItems($where: ElementWhereInput!) {
    elements(where: $where, first: 1000) {
      count
      edges {
        ...elementEdges
      }
    }
  }
  ${ELEMENT_EDGES}
`

export const WISHLIST_RANDOM = gql`
  query wishlistRandom {
    wishlistRandom {
      ...elementFields
    }
  }
  ${ELEMENT_FIELDS}
`

export const WISHLIST_ADD = gql`
  mutation wishlistAdd($ids: [String!], $token: String) {
    wishlistAdd(ids: $ids, token: $token)
  }
`

export const WISHLIST_REMOVE = gql`
  mutation wishlistRemove($ids: [String!], $token: String) {
    wishlistRemove(ids: $ids, token: $token)
  }
`
