import React, { FC, useContext } from 'react'
import Link from 'next/link'
import css from '../MobileNav/MobileNav.module.scss'
import AccordionText from '../../../common/AccordionText/AccordionText'
import cx from 'classnames'
import { mobileNav } from '../../../data'
import MaxWidth from '../../MaxWidth/MaxWidth'
import { LayoutContext } from '../../../../contexts/layoutProvider'

const MobileNav: FC = () => {
  const { mainNav, setMobileNavOpen, julireaNav } = useContext(LayoutContext)

  const handleClick = () => {
    setMobileNavOpen(false)
  }

  return (
    <>
      <MaxWidth>
        {mainNav.length > 0 && (
          <ul className={css.nav}>
            {mainNav.map((item) => (
              <li key={`mob_nav_${item.code}`}>
                {item.items.length > 0 ? (
                  <AccordionText
                    key={`mob_nav_title_${item.code}`}
                    title={
                      <span
                        className={cx(
                          css.title_link,
                          item.code === 'detskaya_mineralnaya_kosmetika' &&
                            css.gradient,
                        )}
                      >
                        {item.title}
                      </span>
                    }
                    className={css.accordion}
                    activeClassName={css.active}
                    descriptionClassName={css.accordionDescription}
                  >
                    {item.items.length > 0 && (
                      <div className={css.items}>
                        <Link
                          key={`${item.code}_${item.code}`}
                          href={item.href}
                          as={item.as}
                          passHref
                        >
                          <a onClick={handleClick} className={css.link}>
                            Все
                          </a>
                        </Link>
                        {item.items.map((link) => (
                          <Link
                            key={`${item.code}_${link.code}`}
                            href={link.href}
                            as={link.as}
                            passHref
                          >
                            <a onClick={handleClick} className={css.link}>
                              {link.title}
                            </a>
                          </Link>
                        ))}
                      </div>
                    )}
                  </AccordionText>
                ) : (
                  <Link href={item.href} as={item.as} passHref>
                    <a
                      className={cx(
                        css.title_link,
                        css.accordion,
                        item.code === 'detskaya_mineralnaya_kosmetika' &&
                          css.gradient,
                      )}
                      onClick={handleClick}
                    >
                      <span className={cx()}>{item.title}</span>
                    </a>
                  </Link>
                )}
              </li>
            ))}
            <>
              <li>
                <AccordionText
                  title={
                    <span className={css.julirea__title}>уходовая линейка</span>
                  }
                  className={cx(css.julirea__accordion, css.accordion)}
                  activeClassName={css.active}
                  descriptionClassName={css.accordionDescription}
                >
                  {julireaNav.length > 0 && (
                    <div className={css.items}>
                      <Link href="/julirea" passHref>
                        <a onClick={handleClick} className={css.link}>
                          Все
                        </a>
                      </Link>
                      {julireaNav.map((link) => (
                        <Link
                          key={link.id}
                          href={`/julirea#julirea-${link.code}`}
                          passHref
                        >
                          <a onClick={handleClick} className={css.link}>
                            {link.title}
                          </a>
                        </Link>
                      ))}
                    </div>
                  )}
                </AccordionText>
              </li>
            </>
          </ul>
        )}

        <ul className={css.menu}>
          {mobileNav?.map((item) => {
            return (
              <li key={`mob-${item.as}-${item.code}`}>
                <Link href={item.href} as={item.as} passHref>
                  <a className={css.item} onClick={handleClick}>
                    {item.title}
                  </a>
                </Link>
              </li>
            )
          })}
        </ul>
      </MaxWidth>
    </>
  )
}

export default MobileNav
