import { FC, useContext } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AuthContext } from '../../../contexts/auth'
import { isNotEmptyInput, requiredMessage } from '../../../lib/form'
import FieldWrapper from '../../fields/FieldWrapper/FieldWrapper'
import Input from '../../fields/Input/Input'
import InputBtn from '../../fields/InputBtn'
import { AuthVariant } from './Auth'
import styles from './Recovery.module.scss'
import Top from './Top'

interface Recovery {
  setAuthVariant: React.Dispatch<React.SetStateAction<AuthVariant>>
  setSuccess: React.Dispatch<React.SetStateAction<string>>
  setError: React.Dispatch<React.SetStateAction<string>>
}

type FormValues = {
  email: string
}

const Recovery: FC<Recovery> = ({ setAuthVariant, setSuccess, setError }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const { recovery, recoveryLoading } = useContext(AuthContext)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (recoveryLoading) {
      return
    }

    const token = await executeRecaptcha()

    const result = await recovery({
      email: data.email,
      token,
    })

    if (result.success) {
      setSuccess(result.message)
    } else {
      setError(result.message)
    }
  }

  return (
    <form
      className={styles.form}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      method="POST"
    >
      <Top
        title="Восстановление"
        question="Нет аккаунта?"
        action="Зарегистрируйтесь"
        toggleForm={() => setAuthVariant(AuthVariant.signup)}
      />

      <FieldWrapper
        label="Электронная почта *"
        errorMessage={errors?.email?.message}
      >
        <Input
          ref={register({
            required: requiredMessage,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Некорректная почта',
            },
            validate: (value) => isNotEmptyInput(value) || requiredMessage,
          })}
          required
          name="email"
          id="email"
          error={errors?.email}
        >
          <InputBtn
            $disabled={!isValid}
            loading={recoveryLoading}
            type="submit"
          />
        </Input>
      </FieldWrapper>
    </form>
  )
}

export default Recovery
