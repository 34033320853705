import React, { FC } from 'react'
import Modal from 'react-modal'

interface IModal {
  children?: React.ReactNode
  open: boolean
}

const MobileNavModal: FC<IModal> = ({ children, open }: IModal) => {
  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      style={{
        content: {
          padding: 0,
          inset: 0,
          border: 0,
          zIndex: 10,
          borderRadius: null,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        overlay: { zIndex: 3 },
      }}
    >
      {children}
    </Modal>
  )
}

export default MobileNavModal
