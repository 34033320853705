import React, { FC } from 'react'
import cx from 'classnames'
import css from './Counter.module.scss'
import PlusIcon from '../../../../public/static/icons/plus.svg'
import MinusIcon from '../../../../public/static/icons/minus.svg'

interface Counter {
  count: number
  setCount: (number: number) => void
  small?: boolean
  allowBelowOne?: boolean
}
const Counter: FC<Counter> = ({ count, setCount, small, allowBelowOne }) => {
  return (
    <div className={cx(css.wrapper, small && css.small)}>
      <button
        type="button"
        title="Уменьшить количество товара"
        className={css.icon}
        onClick={() =>
          setCount(count === 1 && !allowBelowOne ? count : count - 1)
        }
      >
        <MinusIcon />
      </button>
      <div className={css.count}>{count}</div>
      <button
        type="button"
        title="Увеличить количество товара"
        className={css.icon}
        onClick={() => setCount(count + 1)}
      >
        <PlusIcon />
      </button>
    </div>
  )
}

export default Counter
