import { FC, useContext } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { SubmitHandler, useForm } from 'react-hook-form'
import ym from 'react-yandex-metrika'
import { AuthContext } from '../../../contexts/auth'
import { isNotEmptyInput, requiredMessage } from '../../../lib/form'
import FieldWrapper from '../../fields/FieldWrapper/FieldWrapper'
import Input from '../../fields/Input/Input'
import InputBtn from '../../fields/InputBtn'
import { AuthVariant } from './Auth'
import styles from './Login.module.scss'
import SocialAuth from './SocialAuth'
import Top from './Top'

interface Login {
  setAuthVariant: React.Dispatch<React.SetStateAction<AuthVariant>>
  setError: React.Dispatch<React.SetStateAction<string>>
  closeModal: () => void
}

type FormValues = {
  email: string
  password: string
}

const Login: FC<Login> = ({ setAuthVariant, closeModal, setError }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const { signin, signinLoading } = useContext(AuthContext)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (signinLoading) {
      return
    }

    const token = await executeRecaptcha()

    const result = await signin({
      email: data.email,
      password: data.password,
      token,
    })

    if (result.success) {
      closeModal()
      ym('reachGoal', 'km-auth-success')
    } else {
      setError(result.message)
    }
  }

  return (
    <form
      className={styles.form}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      method="POST"
    >
      <Top
        title="Добро пожаловать"
        question="Нет аккаунта?"
        action="Зарегистрируйтесь"
        toggleForm={() => setAuthVariant(AuthVariant.signup)}
      />

      <FieldWrapper
        label="Электронная почта *"
        errorMessage={errors?.email?.message}
      >
        <Input
          ref={register({
            required: requiredMessage,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Некорректная почта',
            },
            validate: (value) => isNotEmptyInput(value) || requiredMessage,
          })}
          required
          name="email"
          id="email"
          error={errors?.email}
        />
      </FieldWrapper>

      <FieldWrapper label="Пароль *" errorMessage={errors?.password?.message}>
        <Input
          ref={register({
            required: requiredMessage,
            validate: (value) => isNotEmptyInput(value) || requiredMessage,
          })}
          required
          type="password"
          name="password"
          id="password"
          error={errors?.password}
        >
          <InputBtn type="submit" $disabled={!isValid} loading={signinLoading}>
            Войти
          </InputBtn>
        </Input>
      </FieldWrapper>

      <p className={styles.text}>
        Забыли пароль?{' '}
        <button
          className={styles.action}
          type="button"
          onClick={() => setAuthVariant(AuthVariant.recovery)}
        >
          Восстановите
        </button>
      </p>

      <FieldWrapper>
        <SocialAuth title="Войти через" />
      </FieldWrapper>
    </form>
  )
}

export default Login
