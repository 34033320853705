import React, { FC, useContext } from 'react'
import css from './ErrorMessage.module.scss'
import cx from 'classnames'
import CloseIcon from '../../../public/static/icons/close-white.svg'
import { LayoutContext } from '../../../contexts/layoutProvider'
import { CSSTransition } from 'react-transition-group'

const ErrorMessage: FC = () => {
  const { commonErrorMessage, setCommonErrorMessage } =
    useContext(LayoutContext)

  return (
    <CSSTransition
      in={!!commonErrorMessage}
      timeout={100}
      classNames="error-fade"
    >
      <div className={cx(css.wrapper)}>
        <button
          className={css.close}
          type="button"
          title="закрыть"
          onClick={() => setCommonErrorMessage('')}
        >
          <CloseIcon />
        </button>
        <h5 className={css.title}>Ошибка</h5>
        <p className={css.text}>{commonErrorMessage}</p>
      </div>
    </CSSTransition>
  )
}

export default ErrorMessage
