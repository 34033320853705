import { useRouter } from 'next/router'
import { FC, useContext } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AuthContext } from '../../../contexts/auth'
import { isNotEmptyInput, requiredMessage } from '../../../lib/form'
import FieldWrapper from '../../fields/FieldWrapper/FieldWrapper'
import Input from '../../fields/Input/Input'
import InputBtn from '../../fields/InputBtn'
import { AuthVariant } from './Auth'
import styles from './Register.module.scss'
import Top from './Top'

interface Change {
  setAuthVariant: React.Dispatch<React.SetStateAction<AuthVariant>>
  setSuccess: React.Dispatch<React.SetStateAction<string>>
  setError: React.Dispatch<React.SetStateAction<string>>
}

type FormValues = {
  password: string
  passwordConfirm: string
}

const Change: FC<Change> = ({ setAuthVariant, setSuccess, setError }) => {
  const { query, replace, pathname } = useRouter()

  const login = query?.login as string
  const checkword = query?.checkword as string

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const { change, changeLoading } = useContext(AuthContext)

  const password = watch('password')

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (changeLoading) {
      return
    }

    const token = await executeRecaptcha()

    const result = await change({
      login,
      checkword,
      password: data.password,
      password2: data.passwordConfirm,
      token,
    })

    if (result?.success) {
      setAuthVariant(AuthVariant.signin)
      setSuccess(result.message)
      replace(pathname, pathname, {
        shallow: true,
      })
    } else {
      setError(result.message)
      replace(pathname, pathname, {
        shallow: true,
      })
    }
  }

  return (
    <form
      className={styles.form}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      method="POST"
    >
      <Top title="Смена пароля" />

      <FieldWrapper
        label="Новый Пароль *"
        errorMessage={errors?.password?.message}
      >
        <Input
          ref={register({
            required: requiredMessage,
            validate: (value) => {
              if (!isNotEmptyInput(value)) {
                return requiredMessage
              }

              if (value.length < 6) {
                return 'Пароль должен быть длиннее 6 символов'
              }
            },
          })}
          required
          type="password"
          name="password"
          id="password"
          error={errors?.password}
        />
      </FieldWrapper>

      <FieldWrapper
        label="Повторите Пароль *"
        errorMessage={errors?.passwordConfirm?.message}
      >
        <Input
          ref={register({
            required: requiredMessage,
            validate: (value) => {
              if (!isNotEmptyInput(value)) {
                return requiredMessage
              }

              if (value.length < 6) {
                return 'Пароль должен быть длиннее 6 символов'
              }

              if (password !== value) {
                return 'Пароли не совпадают'
              }
            },
          })}
          required
          type="password"
          name="passwordConfirm"
          id="passwordConfirm"
          error={errors?.passwordConfirm}
        >
          <InputBtn
            $disabled={!isValid}
            loading={changeLoading}
            type="submit"
          />
        </Input>
      </FieldWrapper>
    </form>
  )
}

export default Change
