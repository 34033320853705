import cx from 'classnames'
import React, { FC } from 'react'
import css from './MaxWidth.module.scss'

interface ILayout {
  className?: string
  wide?: boolean
  children?: React.ReactNode
  mobileWide?: boolean
}

const MaxWidth: FC<ILayout> = ({
  className,
  children,
  wide,
  mobileWide,
}: ILayout) => {
  return (
    <div
      className={cx(
        className,
        css.maxWidth,
        !wide && css.limited,
        mobileWide && css.mobile_wide,
      )}
    >
      {children}
    </div>
  )
}

export default MaxWidth
