import React, { forwardRef } from 'react'
import cx from 'classnames'
import { FieldError } from 'react-hook-form'
import styles from './Input.module.scss'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  icon?: JSX.Element
  error?: FieldError
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', error, children, icon, className, ...props }, ref) => {
    return (
      <div className={styles.wrapper}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <input
          className={cx(
            'input',
            error && 'error',
            children && styles.hasChildren,
            icon && styles.hasIcon,
            className,
          )}
          ref={ref}
          type={type}
          {...props}
        />
        {children && <div className={styles.children}>{children}</div>}
      </div>
    )
  },
)

Input.displayName = 'Input'

export default Input
