import React, { FC, useContext } from 'react'
import css from './Footer.module.scss'

import MaxWidth from '../MaxWidth/MaxWidth'
import FooterNav from './FooterNav/FooterNav'
import Copyright from './Copyright/Copyright'
import PolicyLink from './PolicyLink/PolicyLink'
import KlbrLink from './KlbrLink/KlbrLink'
import Subscribe from '../../forms/Subscribe/Subscribe'
import cx from 'classnames'

import RoundBorderSvg from '../../../public/static/icons/round-border.svg'
import { LayoutContext } from '../../../contexts/layoutProvider'
import { SOCNETS } from '../../data'
import AgreementLink from './AgreementLink/AgreementLink'

const Footer: FC = () => {
  const { more } = useContext(LayoutContext)

  return (
    <footer className={css.container}>
      <MaxWidth>
        <div className={css.wrapper}>
          <div className={css.body}>
            <div className={css.left}>
              <p className={css.left__title}>
                Рассказываем о новых продуктах и макияжах
              </p>
              <ul className={css.socnets}>
                {more?.socNets.map((soc) => {
                  if (!Object.keys(SOCNETS).includes(soc.code)) {
                    return null
                  }

                  return (
                    <li key={soc.id}>
                      <a href={soc.link} target="_blank" rel="noreferrer">
                        <RoundBorderSvg className={css.border} />
                        {SOCNETS[soc.code]}
                      </a>
                    </li>
                  )
                })}
              </ul>
              <Subscribe />
            </div>
            <div className={cx(css.right_descktop, css.right, css.withJulirea)}>
              <FooterNav />
            </div>
          </div>
          <div className={css.bottom}>
            <div className={css.left}>
              <Copyright />
            </div>
            <div className={cx(css.right, css.withJulirea)}>
              <div className={css.right__info}>
                <PolicyLink />
                <AgreementLink />
              </div>
              <KlbrLink />
            </div>
          </div>
        </div>
      </MaxWidth>
    </footer>
  )
}

export default Footer
