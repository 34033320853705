import React, { FC, useContext } from 'react'
import MaxWidth from '../../MaxWidth/MaxWidth'
import css from './MobileSocials.module.scss'
import { LayoutContext } from '../../../../contexts/layoutProvider'
import { SOCNETS } from '../../../data'

const MobileSocials: FC = () => {
  const { more } = useContext(LayoutContext)

  return (
    <MaxWidth>
      <ul className={css.list}>
        {more?.socNets?.map((soc) => {
          if (!Object.keys(SOCNETS).includes(soc.code)) {
            return null
          }

          return (
            <li key={`mobile_${soc.id}`}>
              <a href={soc.link} target="_blank" rel="noreferrer">
                {SOCNETS[soc.code]}
              </a>
            </li>
          )
        })}
      </ul>
    </MaxWidth>
  )
}

export default MobileSocials
