import React, { memo, FC } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

interface IHelmetComp {
  title?: string
  description?: string
  keywords?: string
  ogImage?: string
  isProduct?: boolean
  relLink?: string
}

interface IMeta {
  name?: string
  content: string
  property?: string
  'http-equiv'?: string
}

const TITLE_DEFAULT = 'KMCosmetics'
const DESCRIPTION_DEFAULT = 'KMCosmetics'
const KEYWORDS_DEFAULT = 'KMCosmetics'
const THEME_COLOR = '#3B4047'
const DOMAIN_NAME = process.env.NEXT_PUBLIC_DOMAIN_ENDPOINT
const DEFAULT_OGIMAGE = `${DOMAIN_NAME}/default.jpg`

const HelmetComp: FC<IHelmetComp> = ({
  title = TITLE_DEFAULT,
  description = DESCRIPTION_DEFAULT,
  keywords = KEYWORDS_DEFAULT,
  ogImage = DEFAULT_OGIMAGE,
  isProduct,
  relLink,
}) => {
  const router = useRouter()

  const meta: Array<IMeta> = [
    { name: 'keywords', content: keywords },
    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'format-detection', content: 'telephone=no' },
    { name: 'description', content: description },
    { name: 'theme-color', content: THEME_COLOR },
    { name: 'msapplication-navbutton-color', content: THEME_COLOR },
    { name: 'apple-mobile-web-app-status-bar-style', content: THEME_COLOR },
    { name: 'msapplication-TileColor', content: THEME_COLOR },
    {
      name: 'msapplication-TileImage',
      content: '/static/favicon/apple-icon-144x144.png',
    },
    // Social
    {
      name: 'og:locale',
      content: 'ru_RU',
    },
    {
      name: 'og:image',
      content: ogImage,
    },
    {
      name: 'og:title',
      content: title,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:url',
      content: `${DOMAIN_NAME}${router.asPath}`,
    },
    {
      name: 'og:site_name',
      content: TITLE_DEFAULT,
    },
    {
      name: 'google-site-verification',
      content: '',
    },
    {
      name: 'yandex-verification',
      content: '692f5cf329e1f949',
    },
    {
      name: 'cmsmagazine',
      content: '',
    },
    {
      name: 'zen-verification',
      content:
        '8F9a4OTmtykuA9nIbN7y8etRSctTalNYqbSctP7vK7hHl8sKhCzeecc7aaqkRRnA',
    },
  ]

  if (isProduct) {
    meta.push({
      name: 'twitter:card',
      content: 'summary_large_image',
    })
  }

  const links = [
    {
      rel: 'manifest',
      href: '/manifest.json',
    },
    { rel: 'manifest', href: '/static/favicon/manifest.json' },
    {
      rel: 'apple-touch-icon',
      sizes: '57x57',
      href: '/static/favicon/apple-icon-57x57.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      href: '/static/favicon/apple-icon-60x60.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      href: '/static/favicon/apple-icon-72x72.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      href: '/static/favicon/apple-icon-76x76.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      href: '/static/favicon/apple-icon-114x114.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: '/static/favicon/apple-icon-120x120.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      href: '/static/favicon/apple-icon-144x144.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: '/static/favicon/apple-icon-152x152.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/static/favicon/apple-icon-180x180.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: '/static/favicon/android-icon-192x192.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/static/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '96x96',
      href: '/static/favicon/favicon-96x96.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/static/favicon/favicon-16x16.png',
    },
  ]

  return (
    <Head>
      <title>{title}</title>
      {meta.map((metaItem) => (
        <meta
          key={JSON.stringify(metaItem)}
          name={metaItem.name}
          content={metaItem.content}
        />
      ))}
      {links.map((link) => {
        if (link.type && link.sizes) {
          return (
            <link
              key={JSON.stringify(link)}
              rel={link.rel}
              href={link.href}
              type={link.type}
              sizes={link.sizes}
            />
          )
        } else if (link.sizes) {
          return (
            <link
              key={JSON.stringify(link)}
              rel={link.rel}
              href={link.href}
              sizes={link.sizes}
            />
          )
        } else {
          return (
            <link key={JSON.stringify(link)} rel={link.rel} href={link.href} />
          )
        }
      })}
      {relLink && <link rel="canonical" href={relLink} />}
    </Head>
  )
}

export default memo(HelmetComp)
