import React, { FC } from 'react'
import css from './PolicyLink.module.scss'

const PolicyLink: FC = () => {
  return (
    <a
      href="/info/policy"
      target="_blank"
      className={css.link}
      title="Политика обработки персональных данных"
    >
      Политика обработки персональных данных
    </a>
  )
}

export default PolicyLink
