import React, { FC, useContext, useState } from 'react'
import css from './Subscribe.module.scss'
import { useForm } from 'react-hook-form'
import { requiredMessage } from '../../../lib/form'
import ArrowIcon from '../../../public/static/icons/arrow-left.svg'
import cx from 'classnames'
import { useMutation } from '@apollo/client'
import { sendForm, sendFormVariables } from '../../../__generated__/sendForm'
import { SEND_FORM } from '../../../queries/formMutation'
import LoadingIcon from '../../../public/static/icons/form-loader.svg'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { LayoutContext } from '../../../contexts/layoutProvider'
import ToggleCheckBox from '../../fields/ToggleCheckBox/ToggleCheckBox'
import FieldWrapper from '../../fields/FieldWrapper/FieldWrapper'
import ym from 'react-yandex-metrika'
import CloseIcon from '../../../public/static/icons/close-white.svg'

interface ISubscribeForm {
  email: string
  privacyPolicy: boolean
}

const Subscribe: FC = () => {
  const [success, setSuccess] = useState(false)
  const [notification, setNotification] = useState<string>('')
  const { register, errors, handleSubmit, reset } = useForm<ISubscribeForm>({
    mode: 'all',
  })
  const { setCommonErrorMessage } = useContext(LayoutContext)

  const [sendForm, { loading }] = useMutation<sendForm, sendFormVariables>(
    SEND_FORM,
  )

  const { executeRecaptcha } = useGoogleReCaptcha()

  const submit = async (values) => {
    try {
      setCommonErrorMessage('')
      setNotification('')

      const token = await executeRecaptcha()
      const res = await sendForm({
        variables: {
          form: 'form-subscribe',
          name: `Подписка от ${values.email}`,
          data: values,
          token,
        },
      })

      if (res?.data?.sendForm?.success) {
        setSuccess(true)

        ym('reachGoal', 'email-form-submit')

        setTimeout(() => {
          setSuccess(false)
          reset()
        }, 5000)
      } else {
        if (res?.data?.sendForm?.message === 'Введеный адрес уже подписан') {
          setNotification(
            'Введенный Вами адрес e-mail уже находится в&nbsp;списке рассылки новостей',
          )
        } else {
          throw new Error(
            'Возникла ошибка при отправки формы. Воспользуйтесь формой позже.',
          )
        }
      }
    } catch (error) {
      setCommonErrorMessage(
        'Возникла ошибка при отправки формы. Воспользуйтесь формой позже.',
      )
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className={css.container}>
      <div className={css.wrapper}>
        <input
          name="email"
          type="email"
          ref={register({
            required: requiredMessage,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Некорректная почта',
            },
          })}
          className={cx(
            css.input,
            errors?.email?.message && css.error,
            success && css.success,
          )}
          placeholder="Укажите ваш e-mail"
        />
        <button type="submit" title="Подписаться" className={css.button}>
          <ArrowIcon />
        </button>

        {errors?.email?.message && (
          <p className={css.error_message}>{errors?.email?.message}</p>
        )}
      </div>

      <FieldWrapper
        errorMessage={errors?.privacyPolicy?.message}
        errorClassName={css.privacy_error}
        className={css.field_wrapper}
      >
        <ToggleCheckBox
          defaultChecked
          name="privacyPolicy"
          id="privacyPolicy"
          label={
            <>
              согласен с условиями{' '}
              <a href="/info/policy" target="_blank" className={css.policy}>
                политики в отношении обработки персональных данных.
              </a>
            </>
          }
          ref={register({
            validate: (value) => {
              if (!value) {
                return requiredMessage
              }
            },
          })}
        />
      </FieldWrapper>

      {loading && (
        <div className={css.loader}>
          <LoadingIcon />
        </div>
      )}

      {success && (
        <p className={css.success_message}>Поздравляем с подпиской!</p>
      )}
      {notification && (
        <div className={css.notification}>
          <p dangerouslySetInnerHTML={{ __html: notification }} />
          <button
            onClick={() => setNotification('')}
            className={css.closeNotification}
            aria-label="Закрыть уведомление"
            type="button"
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </form>
  )
}

export default Subscribe
