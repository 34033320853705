import { INavItem, INavCategory, SocNets } from './types'
import SearchIcon from '../public/static/icons/search.svg'
import HeartIcon from '../public/static/icons/heart.svg'
import PersonIcon from '../public/static/icons/person.svg'
import CartIcon from '../public/static/icons/cart.svg'
import { IHeaderButton } from './layout/Header/HeaderButton/HeaderButton'

import VKIcon from '../public/static/icons/vk.svg'
import FacebookIcon from '../public/static/icons/facebook.svg'
import YoutubeIcon from '../public/static/icons/youtube.svg'
import TiktokIcon from '../public/static/icons/tiktok.svg'
import TGIcon from '../public/static/icons/tg-more.svg'
import DZIcon from '../public/static/icons/dzen.svg'

export const CATALOG_HREF = '/catalog/[section]/[[...path]]'
export const JULIREA_HREF = '/julirea/[code]/'

export const PRODUCT_NO_IMAGE = '/static/images/product-no-image.jpg'

export const STORIES_NO_COVER = '/static/images/stories-empty.png'

export const headerButtons: IHeaderButton[] = [
  {
    code: 'search',
    href: '/search',
    title: 'Поиск',
    Icon: SearchIcon,
  },
  {
    code: 'favorites',
    href: '/favorites',
    title: 'Избранное',
    Icon: HeartIcon,
  },
  {
    code: 'personal',
    href: '/personal',
    title: 'Личный кабинет',
    Icon: PersonIcon,
  },
  {
    code: 'checkout',
    href: '/checkout',
    title: 'Корзина',
    Icon: CartIcon,
  },
]

export const secondNav: INavCategory[] = [
  {
    code: 'interest',
    title: 'Интересно',
    items: [
      {
        code: 'aktsii',
        href: '/journal/aktsii',
        title: 'Акции',
      },
      {
        code: 'lookbook',
        href: '/lookbook',
        title: 'LookBook',
      },
      {
        code: 'journal',
        href: '/journal',
        title: 'Макияж и статьи',
      },
    ],
  },
  {
    code: 'help',
    title: 'Помощь',
    items: [
      {
        code: 'delivery',
        href: '/info/dostavka',
        title: 'Доставка',
      },
      {
        code: 'refund',
        href: '/info/vozvrat',
        title: 'Возврат',
      },
      {
        code: 'payment',
        href: '/info/oplata',
        title: 'Оплата',
      },
    ],
  },
  {
    code: 'about',
    title: 'О нас',
    items: [
      {
        code: 'reviews',
        href: '/reviews',
        title: 'Отзывы',
      },
      {
        code: 'about-cosmetics',
        href: '/about/cosmetics',
        title: 'О косметике',
      },
      {
        code: 'company',
        href: '/about/company',
        title: 'О компании',
      },
    ],
  },
  {
    code: 'contacts',
    title: 'Для связи',
    items: [
      {
        code: 'contacts',
        href: '/contacts',
        title: 'Контакты',
      },
      {
        code: 'contacts2',
        href: '/where-to-buy',
        title: 'Где купить',
      },
      {
        code: 'contacts3',
        href: '/wholesale',
        title: 'Оптовым клиентам',
      },
    ],
  },
]

export const footerNav: INavCategory[] = [
  {
    code: 'clients',
    title: 'Для клиентов',
    items: [
      {
        code: 'delivery',
        href: '/info/dostavka',
        title: 'Доставка',
      },
      {
        code: 'refund',
        href: '/info/vozvrat',
        title: 'Возврат',
      },
      {
        code: 'payment',
        href: '/info/oplata',
        title: 'Оплата',
      },
      {
        code: 'contacts',
        href: '/contacts',
        title: 'Контакты',
      },
      {
        code: 'clients5',
        href: '/where-to-buy',
        title: 'Где купить',
      },
    ],
  },
  {
    code: 'about',
    title: 'О нас',
    items: [
      {
        code: 'company',
        href: '/about/company',
        title: 'О компании',
      },
      {
        code: 'about-cosmetics',
        href: '/about/cosmetics',
        title: 'О косметике',
      },
      {
        code: 'journal',
        href: '/journal',
        title: 'Макияж и статьи',
      },
      {
        code: 'reviews',
        href: '/reviews',
        title: 'Отзывы',
      },
      {
        code: 'about5',
        href: '/wholesale',
        title: 'Опт',
      },
    ],
  },
]

export const mobileNav: INavItem[] = [
  {
    code: 'lookbook',
    href: '/lookbook',
    title: 'LookBook',
  },
  {
    code: 'aktsii',
    href: '/journal/aktsii',
    title: 'Акции',
  },
  {
    code: 'about-cosmetics',
    href: '/about/cosmetics',
    title: 'О косметике',
  },
  {
    code: 'mobile4',
    href: '/journal',
    title: 'Макияж и статьи',
  },
  {
    code: 'company',
    href: '/about/company',
    title: 'О компании',
  },
  {
    code: 'reviews',
    href: '/reviews',
    title: 'Отзывы',
  },
  {
    code: 'delivery',
    href: '/info/dostavka',
    title: 'Доставка',
  },
  {
    code: 'refund',
    href: '/info/vozvrat',
    title: 'Возврат',
  },
  {
    code: 'payment',
    href: '/info/oplata',
    title: 'Оплата',
  },
  {
    code: 'contacts',
    href: '/contacts',
    title: 'Контакты',
  },
  {
    code: 'mobile11',
    href: '/where-to-buy',
    title: 'Где купить',
  },
  {
    code: 'mobile12',
    href: '/wholesale',
    title: 'Опт',
  },
]

export const SOCNETS: Record<SocNets, React.SVGProps<SVGSVGElement>> = {
  facebook: <FacebookIcon />,
  vk: <VKIcon />,
  youtube: <YoutubeIcon />,
  tiktok: <TiktokIcon />,
  tg: <TGIcon />,
  dzen: <DZIcon />,
}
