import gql from 'graphql-tag'
import { ELEMENT_FIELDS, PRODUCT_FIELDS } from './catalogQuery'
import { ELEMENT_SEO_FIELDS } from './seoQuery'

export const JULIREA_BANNER = gql`
  query julirea_banner($where: SettingPlusWhereInput) {
    settingPluses(where: $where) {
      id
      code
      value
      targetFile {
        id
        url
      }

      targetElement {
        id
        code

        sections {
          id
          code
        }
      }
    }
  }
`

export const JULIREA_CATALOG = gql`
  query julirea_catalog($where: SectionWhereInput!, $first: Int) {
    sections(where: $where, first: $first, order: { sort: asc }) {
      nodes {
        id
        code
        name

        previewPicture {
          id
          url
        }

        elements(where: { active: { equals: true } }, orderBy: { sort: asc }) {
          ...elementFields
          sku(where: { active: { equals: true } }) {
            id
            name
            priceBase
            priceBaseWithDiscount
          }
        }
      }
    }
  }
  ${ELEMENT_FIELDS}
`

export const JULIREA_NAV = gql`
  query julirea_nav($where: SectionWhereInput!, $first: Int) {
    sections(where: $where, first: $first, order: { sort: asc }) {
      nodes {
        id
        code
        name

        elements(first: 1) {
          id
        }
      }
    }
  }
`

export const JULIREA_PRODUCT = gql`
  query julirea_product($where: ElementWhereInput!, $today: DateTime) {
    element(where: $where) {
      ...elementFields
      ...productFields
      ...elementSeoFields

      propertyValuesRelation {
        targetElements {
          ...elementFields
          ...productFields
        }

        targetFiles {
          id
          url
        }

        targetElement {
          id
          name
          code
          detailText
          detailPicture {
            id
            url
          }
          propertyValuesRelation {
            id
            property {
              id
              code
            }

            targetFile {
              id
              url
            }
          }
        }
      }
    }
  }
  ${ELEMENT_FIELDS}
  ${ELEMENT_SEO_FIELDS}
  ${PRODUCT_FIELDS}
`
