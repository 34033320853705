import { FC } from 'react'
import styles from './EmptyCart.module.scss'
import Logo from '../../../public/static/icons/logo.svg'
import cx from 'classnames'

interface EmptyCart {
  className?: string
}
const EmptyCart: FC<EmptyCart> = ({ className }) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      <Logo />
      <h4 className={styles.header}>Ваша корзина пуста</h4>
      <p className={styles.text}>У вас нет товаров в корзине!</p>
    </div>
  )
}

export default EmptyCart
